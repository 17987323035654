import http from "@/http";

export default {
  getDonationPageBySlug: (organization_code, slug) =>
    http.get(`/api/organizations/${organization_code}/slugs/${slug}/page`),
  getDefaultPage: () => http.get("/api/default-page"),
  getDefaultPageByOrganization: organization_code =>
    http.get(`/api/organizations/${organization_code}/default-page`),
  getDonationGalleryPagesByOrganization: organization_code =>
    http.get(`/api/organizations/${organization_code}/donation-gallery-pages`),
  getOrganizations: () => http.get("/api/organizations")
};
