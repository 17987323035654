<template>
  <div>
    <div
      class="prose donate-card__content"
      v-html="contents.content"
    />
    <div class="donate-card__types">
      <template v-if="isOneTimeVisible">
        <input
          id="one-time"
          v-model="payment_type"
          type="radio"
          value="one-time"
          class="donate-card__radio"
        >
        <label
          :class="{ selected: payment_type === 'one-time' }"
          for="one-time"
          class="donate-card__radio_label"
        >One Time Gift</label>
      </template>
      <template v-if="isMonthlyVisible">
        <input
          id="monthly"
          v-model="payment_type"
          type="radio"
          value="monthly"
          class="donate-card__radio"
        >
        <label
          :class="{ selected: payment_type === 'monthly' }"
          for="monthly"
          class="donate-card__radio_label"
        >Monthly</label>
      </template>
    </div>
    <div
      v-if="payment_type === 'monthly'"
      class="donate-card__monthly"
    >
      When you give monthly, you can support ADRA's work all year round. Please
      enter your tax-deductible monthly contribution.
    </div>
    <template v-if="!isBlackbaudActive">
      <div class="donate-card__buttons">
        <button
          v-for="(amount_value, index) in contents.amount_values"
          :key="index"
          :class="{ selected: value === amount_value.numeric_value }"
          class="donate-card__button"
          @click="changeAmount(amount_value)"
        >
          {{ currentSymbol }} {{ amount_value.numeric_value }}
        </button>
      </div>
      <DonateCardStepOneAmount
        v-model="amount"
        :currency-code="currencyCode"
        :currency-on-right="currencyOnRight"
        :currency-data="currencies"
      />
      <div
        v-if="contents.multiplier > 1"
        class="mb-4"
      >
        <div class="donate-card__double_impact">
          {{ contents.impact_content }}
        </div>
        <div
          v-if="totalAmount > 0"
          class="donate-card__double_impact_gift"
        >
          Your gift becomes
          <span class="donate-card__double_amount">${{ totalAmount }}</span>
        </div>
      </div>
      <div
        v-if="externalSupportSlugs.length > 0"
        class="mb-4"
      >
        <select
          v-model="localValue"
          class="donate-card__input_full"
          name="title"
          @change="getRoute"
        >
          <option
            value=""
            disabled
          >
            Supporting donation pages from other countries
          </option>
          <option
            v-for="(item, index) in externalSupportSlugs"
            :key="index"
            :value="item"
          >
            {{ item.organization.name }}
          </option>
        </select>
      </div>
    </template>
    <template v-else>
      <DonateCardBlackbaudIframe
        class="mb-4"
        :iframe-url="blackbaudUrl"
      />
    </template>

    <span
      v-if="contents.is_help_visible === '1'"
      class="donate-card__taxes"
    >{{
      helpText
    }}</span>
  </div>
</template>
<script>
import DonateCardStepOneAmount from './DonateCardStepOneAmount'
import { mapGetters, mapActions } from 'vuex'
import DonateCardBlackbaudIframe from './DonateCardBlackbaudIframe'
import currencies from '@/helpers/currencies'

export default {
  name: 'DonateCardStepOne',

  components: {
    DonateCardStepOneAmount,
    DonateCardBlackbaudIframe,
  },

  props: {
    contents: {
      type: Object,
      default: () => ({}),
    },

    currencyCode: {
      type: String,
      default: 'USD',
    },

    currencyOnRight: {
      type: Boolean,
      default: false,
    },

    value: {
      type: String,
      required: true,
    },
    isBlackbaudActive: {
      type: Boolean,
      default: false,
    },
    blackbaudUrl: {
      type: String,
      default: null,
    },

    currencies: {
      type: Object,
      default: () => ({}),
    },

    externalSupportSlugs: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      payment_type: 'one-time',
      amount: '',
      activeNames: '',
      localValue: '',
    }
  },
  computed: {
    ...mapGetters(['currencySymbol']),
    currentSymbol() {
      const curr = currencies[this.currencyCode]
      return curr ? curr.symbol : this.currencySymbol
    },
    paymentTypeDisplay() {
      // check for manual override in params
      const donationDisplay = this.$route.query['donation-display']

      return donationDisplay || this.contents.donation_option_type || null
    },
    isMonthlyVisible() {
      // newer paymentTypeDisplay has priority
      if (!this.paymentTypeDisplay) {
        return this.contents.allow_monthly_payments === '1'
      }
      return this.paymentTypeDisplay !== 'one-time-only'
    },
    isOneTimeVisible() {
      // null, it's not set, so an for older created donation page (< May 10th 2020)
      if (!this.paymentTypeDisplay) {
        return true
      }
      return this.paymentTypeDisplay !== 'monthly-only'
    },
    totalAmount() {
      if (!this.value || !this.contents) return 0

      return this.value * this.contents.multiplier
    },

    helpText() {
      if (typeof this.contents.help_text === 'undefined') {
        return 'Donations are tax-deductible for US residents and will receive a receipt.'
      }
      return this.contents.help_text
    },

    location() {
      return window.location.origin
    },
  },

  watch: {
    amount(value) {
      this.$emit('input', value)
    },
    payment_type(value) {
      this.$emit('update:paymentType', value)
    },
  },
  mounted() {
    if (
      this.paymentTypeDisplay !== 'monthly-only' &&
      this.paymentTypeDisplay !== 'monthly-default'
    ) {
      return (this.payment_type = 'one-time')
    }
    return (this.payment_type = 'monthly')
  },

  created() {
    this.amount = this.value
  },

  methods: {
    ...mapActions({
      setCurrentCurrency: 'setCurrentCurrency',
    }),

    getRoute() {
      const href = `${this.location}/${this.localValue.organization.country_code}/${this.localValue.slug}`
      window.location.href = href
      window.location.replace(href)
    },

    changeAmount(amount_value) {
      this.amount = amount_value.numeric_value
      this.setCurrentCurrency(this.currencyCode)
    },
  },
}
</script>
