<template>
  <select
    class="donate-card__input_half donate-card__input_half_l"
    required
    @change="$emit('input', $event.target.value)"
  >
    <option value selected disabled>Month *</option>
    <option
      v-for="month in months"
      :key="month"
      :value="month"
      :selected="isSameMonth(month)"
      >{{ month }}</option
    >
  </select>
</template>
<script>
export default {
  name: "DonateCardStepTwoSelectMonth",
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ]
    };
  },
  methods: {
    isSameMonth(month) {
      return Number(month) === Number(this.value);
    }
  }
};
</script>
