<template>
  <BaseModal @close="$emit('close')">
    <div
      slot="body"
      class="flex flex-col my-10 p-6 w-full max-w-md border-grey-light border rounded-none bg-white"
    >
      <div class="pb-6">
        <span>{{ message }}</span>
      </div>

      <div>
        <button class="button button-green button-sm mr-2" @click="$emit('ok')">
          OK
        </button>
        <button class="button button-grey button-sm" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: "BaseConfirmationBox",

  props: {
    message: {
      type: String,
      default: ""
    }
  }
};
</script>
