<template>
  <div>
    <BaseModalForm v-if="isValidating">
      <div slot="header">
        <h1 class="auth__title">Change password</h1>
      </div>
      <div slot="body" class="flex justify-center">
        <SpinnerIcon
          class="fill-current text-teal-500 inline-block h-12 w-12"
        />
        <div>
          Loading...
        </div>
      </div>
    </BaseModalForm>
    <BaseModal
      v-else-if="!isValidating && tokenValid"
      @password-changed="handlePasswordChanged"
    >
      <UserChangePassword slot="body" :email="email" :token="token" />
    </BaseModal>
    <BaseModalForm v-else-if="!isValidating && !tokenValid">
      <div slot="header">
        <h1 class="auth__title">Change password</h1>
      </div>
      <div slot="body" class="text-center">
        <h4>Token is expired. Please try again.</h4>
      </div>
      <div slot="footer" class="auth__buttons">
        <button
          class="auth__buttons__link"
          type="button"
          @click.self="goToForgotPassword"
        >
          Forgot Password?
        </button>
        <button
          class="button button-green"
          type="button"
          @click.self="goToSignIn"
        >
          Login
        </button>
      </div>
    </BaseModalForm>
  </div>
</template>

<script>
import PageService from "../api/PageService";
import AuthAPI from "../api/auth";
import SpinnerIcon from "@/assets/i-circle-spinner.svg";
import UserChangePassword from "@/components/Auth/UserChangePassword";

export default {
  name: "ResetPassword",

  components: {
    UserChangePassword,
    SpinnerIcon
  },

  props: {
    email: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tokenValid: false,
      isValidating: true
    };
  },

  async created() {
    let result = await PageService.getDefaultPage();
    try {
      this.isValidating = true;
      await AuthAPI.validateChangePasswordToken({
        email: this.email,
        token: this.token
      });
      this.tokenValid = true;
    } catch {
      this.tokenValid = false;
    }
    this.isValidating = false;
    this.initializePage(result.data.data);
  },

  methods: {
    initializePage(data) {
      let bodyElement = document.body;
      bodyElement.style.background = `#322d2b url('${data.background_image}') no-repeat center center`;
      bodyElement.style.backgroundAttachment = "fixed";
      bodyElement.style.backgroundSize = "cover";
    },
    goToForgotPassword() {
      this.$emit("open-forgot-password");
      this.$router.push({
        name: "DonatePage"
      });
    },
    goToSignIn() {
      this.$emit("open-signin");
      this.$router.push({
        name: "DonatePage"
      });
    },
    handlePasswordChanged() {
      this.$router.push({
        name: "DonatePage"
      });
    }
  }
};
</script>
