import axios from "axios";
import store from "@/store";
import router from "@/router";

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

// Add the authorization header on requests
http.interceptors.request.use(
  config => {
    let token = store.getters["auth/authorizationHeader"];
    config.headers = {
      ...config.headers,
      "X-Requested-With": "XMLHttpRequest"
    };

    if (token && config.shouldAuthenticate === true) {
      config.headers = { ...config.headers, Authorization: token };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Refresh JWT if 401 is returned
http.interceptors.response.use(undefined, error => {
  let errorResponse = error.response;
  if (
    errorResponse.status === 401 &&
    errorResponse.data.error === "Token expired" &&
    errorResponse.config &&
    !errorResponse.config.__isRetryRequest
  ) {
    return new Promise((resolve, reject) => {
      store
        .dispatch("auth/refresh")
        .then(() => {
          errorResponse.config.__isRetryRequest = true;
          errorResponse.config.headers.Authorization =
            store.getters["auth/authorizationHeader"];
          resolve(axios(errorResponse.config));
        })
        .catch(errored => {
          store.dispatch("auth/reset");
          router.push({
            name: "UserLogin"
          });
          reject(errored);
        });
    });
  }

  return Promise.reject(errorResponse);
});

export default http;
