<template>
  <multiselect
    v-model="selectedCountry"
    :options="sortedCountries"
    :option-height="40"
    :show-labels="false"
    label="value"
    track-by="key"
    placeholder="Country"
    @input="handleSelectedCountryChange"
  >
    <template slot="option" slot-scope="props">
      <div class="flex">
        <div class="max-w-6">
          <div class="relative pb-2/3 flex">
            <img
              :src="props.option.flag"
              :alt="props.option.title"
              class="h-full w-full object-cover"
            />
          </div>
        </div>
        <div class="pl-2">{{ props.option.value }}</div>
      </div>
    </template>
  </multiselect>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  name: "DonateCardStepTwoSelectCountry",

  components: {
    Multiselect
  },

  props: {
    countries: {
      type: [Array, Object],
      required: true
    },
    value: {
      type: String,
      required: true
    },
    firstCountry: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      selectedCountry: null
    };
  },

  computed: {
    sortedCountries() {
      let mappedCountriesList = Object.keys(this.countries).map(key => ({
        key,
        value: this.countries[key][0][0],
        flag: this.countries[key][0][1]
      }));

      const firstCountryIndex = mappedCountriesList.findIndex(
        x => x.key === this.firstCountry
      );
      if (firstCountryIndex > -1) {
        mappedCountriesList.unshift(
          mappedCountriesList.splice(firstCountryIndex, 1)[0]
        );
      }
      return mappedCountriesList;
    }
  },

  watch: {
    value: {
      handler(newValue) {
        this.selectedCountry = this.sortedCountries.find(
          x => x.key === newValue
        )
          ? { ...this.sortedCountries.find(x => x.key === newValue) }
          : null;
      },
      immediate: true
    }
  },

  methods: {
    handleSelectedCountryChange(event) {
      this.$emit("input", event.key);
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: #afbac6;
  border-radius: 0.125rem;
}

.multiselect__tags {
  border: none;
  outline: none;
}

.multiselect__placeholder {
  color: #6B7280;
  font-size: 16px;
}
</style>
