import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import InputMixin from '@/mixins/input'
import '@/components/base/_globals'
import 'element-ui/lib/theme-chalk/index.css'
import { Tooltip, Select, Option } from 'element-ui'
import './main.css'
import Toasted from 'vue-toasted'
import '@/directives/loading'

Vue.config.productionTip = false

Vue.mixin(InputMixin)
Vue.use(Vuelidate)
Vue.use(Toasted)
Vue.use(Tooltip)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

store.dispatch('reset')
