<template>
  <div>
    <h2 class="donate-card__thanks-heading">Thank you for your donation.</h2>
    <div class="donate-card__content pb-6">{{ content }}</div>
    <div class="donate-card__summary">
      <div class="flex">
        <span>Name:</span>
        {{ get(donation, "customer_data.first_name") }}
        {{ get(donation, "customer_data.last_name") }}
      </div>
      <div class="flex">
        <span>Address:</span>
        <div>
          <p>
            {{ get(donation, "customer_data.billing_address.address_line1") }}
          </p>
          <p
            v-if="get(donation, 'customer_data.billing_address.address_line2')"
          >
            {{ get(donation, "customer_data.billing_addressaddress_line2") }}
          </p>
          <p>
            {{ get(donation, "customer_data.billing_address.city") }},
            {{ get(donation, "customer_data.billing_address.region") }}
            {{ get(donation, "customer_data.billing_address.postal_code") }}
            {{ get(donation, "customer_data.billing_address.country") }}
          </p>
        </div>
      </div>
      <div class="flex">
        <span>Email Address:</span>
        {{ get(donation, "customer_data.email") }}
      </div>
      <div class="flex">
        <span>Appeal:</span>
        {{ get(donation, "appeal.name") }}
      </div>
      <div class="flex">
        <span>Donation Date:</span>
        {{ today }}
      </div>
      <div class="flex">
        <span>Gift Amount:</span>
        {{ currencyCode || currencySymbol }} {{ get(donation, "amount") }}
      </div>
      <div class="flex">
        <span>Order Number:</span>
        {{ get(donation, "order_number") }}
      </div>
    </div>
    <CreateNewPinForm
      v-if="shouldCreatePin"
      :user-token="get(donation, 'user.token')"
      :auth-token="get(donation, 'token')"
      @saved="shouldCreatePin = false"
    />
    <a
      :href="continueLink"
      class="block text-center donate-card__button donate-card__submit"
      >Continue browsing</a
    >
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { format } from "date-fns";
import get from "lodash.get";
import CreateNewPinForm from "./CreateNewPinForm";

export default {
  name: "DonateCardSuccess",

  components: {
    CreateNewPinForm
  },

  props: {
    content: {
      type: String,
      default: ""
    },
    donation: {
      type: Object,
      default() {
        return {
          id: "",
          amount: 0,
          appeal: {}
        };
      }
    },
    shouldCreatePin: {
      type: Boolean,
      default: false
    },
    currencyCode: {
        type: String,
      default: null,
    },

  },

  data() {
    return {
      get
    };
  },

  computed: {
    continueLink() {
      return this.referrer && this.referrer !== ""
        ? this.referrer
        : "https://adra.org";
    },

    ...mapState(["referrer"]),

    ...mapGetters(["currencySymbol"]),

    today() {
      return format(new Date(), "yyyy/MM/dd");
    }
  }
};
</script>
<style lang="postcss" scoped>
span {
  width: 110px;
}
</style>
