<template>
  <BaseModal @close="$emit('close')">
    <div
      slot="body"
      class="flex flex-col my-10 p-6 w-full max-w-md border-grey-light border rounded-none bg-white"
    >
      <form class="auth__form">
        <div class="auth__form__section auth__header">
          <slot name="header">
            <h1 class="auth__title">Welcome Back!</h1>
            <h4 class="auth__subtitle">Login below to get started!</h4>
          </slot>
          <hr class="auth__hr" />
        </div>
        <div class="auth__form__section">
          <slot name="body" />
          <slot name="footer">
            <div class="auth__buttons" />
          </slot>
        </div>
      </form>
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: "BaseModalForm"
};
</script>
