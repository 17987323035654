import getters from "./getters";
import mutations from "./mutations";
import mutationTypes from "./mutation-types";
import actions from "./actions";
import state from "./state";

export default {
  getters,
  mutations,
  actions,
  state,
  mutationTypes
};
