<template>
  <div
    class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
  >
    <!-- Breadcrumb -->
    <div class="breadcrumb">
      <ul class="breadcrumb__wrapper">
        <li class="breadcrumb__item">
          <router-link :to="{ name: 'DonatePage' }">Home</router-link>
          <CheveronRight class="mx-2" width="20px" height="20px" />
        </li>
        <li class="breadcrumb__item">
          <span class="breadcrumb__item__link">Donation Pages</span>
        </li>
      </ul>
    </div>
    <!-- End of Breadcrumb -->

    <div class="relative max-w-7xl mx-auto">
      <div class="mt-12 grid gap-5 mx-auto lg:grid-cols-4 lg:max-w-none">
        <GalleryCard
          v-for="(page, index) in externalPages"
          v-show="!isLoading"
          :page="page"
          :country-code="code"
          :key="index"
        />
        <BaseCardLoader v-show="isLoading" />
        <BaseCardLoader v-show="isLoading" />
        <BaseCardLoader v-show="isLoading" />
        <BaseCardLoader v-show="isLoading" />
      </div>
      <div
        v-show="!isLoading && externalPages.length == 0"
        class="text-center text-xl"
      >
        There is no donation gallery pages associated with the selected
        organization
      </div>
    </div>
  </div>
</template>

<script>
import PageService from "../api/PageService";
import CheveronRight from "@/assets/cheveron-right.svg";
import GalleryCard from "@/components/Gallery/GalleryCard";

export default {
  name: "DonationPages",

  components: {
    CheveronRight,
    GalleryCard
  },

  props: {
    code: {
      type: String,
      default: process.env.VUE_APP_ORGANIZATION_COUNTRY_CODE
    }
  },

  data() {
    return {
      externalPages: [],
      organizations: [],
      selectedOrganization: "",
      isLoading: false
    };
  },

  created() {
    this.isLoading = true;
    Promise.all([
      PageService.getOrganizations(),
      this.getExternalPages(this.code)
    ])
      .then(([getOrgData, getExternalPagesData]) => {
        this.organizations = getOrgData.data.data;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
  },

  methods: {
    handleClick() {
      this.$router.push({
        name: "DonatePage"
      });
    },
    async getExternalPages(countryCode) {
      this.isLoading = true;
      try {
        const result = await PageService.getDonationGalleryPagesByOrganization(
          countryCode
        );
        this.externalPages = result.data.data;
        return result;
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    }
  }
};
</script>
