<template>
  <content-loader
    :width="400"
    :height="475"
    :speed="2"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect x="0" y="154" rx="2" ry="2" width="276" height="14" />
    <rect x="-7" y="4" rx="2" ry="2" width="280" height="129" />
    <rect x="0" y="186" rx="2" ry="2" width="276" height="14" />
    <rect x="1" y="218" rx="2" ry="2" width="276" height="14" />
  </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  name: "BaseCardLoader",
  components: {
    ContentLoader
  }
};
</script>
