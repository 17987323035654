export default {
  fullName: state => {
    return state.user
      ? `${state.user.first_name} ${state.user.last_name}`
      : null;
  },

  firstName: state => {
    return state.user ? state.user.first_name : null;
  },

  authorizationHeader: state => {
    return state.token ? `Bearer ${state.token}` : null;
  },

  isLoggedIn: state => state.loggedIn,

  userToken: state => {
    return state.user ? state.user.token : null;
  },

  user: state => state.user,

  authToken: state => state.token
};
