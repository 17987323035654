import types from "./mutation-types";

export default {
  [types.updateLoggedIn](state, loggedIn) {
    state.loggedIn = loggedIn;
  },

  [types.updateToken](state, token) {
    state.token = token;
  },

  [types.updateUser](state, user) {
    state.user = user;
  }
};
