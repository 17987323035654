<template>
  <div class="input-group">
    <input
      ref="input"
      :class="{
        'is-valid': valid,
        'is-invalid': invalid
      }"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="localValue"
      class="input"
      @input="onInput"
      @blur="$emit('blur')"
    />
    <i v-if="icon" class="form-control-icon material-icons">{{ icon }}</i>
    <div
      v-if="invalidVisible"
      :class="{ 'invalid-feedback-visible': invalidVisible }"
      class="invalid-feedback"
    >
      {{ invalidMessage }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      default: null
    },

    invalidMessage: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: ""
    },

    type: {
      type: String,
      default: "text"
    },

    value: {
      type: [String, Number],
      default: null
    },

    state: {
      type: [Boolean, String],
      default: null
    }
  },

  data() {
    return {
      cleaveInput: null,
      localValue: this.value
    };
  },

  computed: {
    iconClass() {
      return `fa fa-${this.icon}`;
    },

    invalidId() {
      if (!this.invalidMessage) {
        return null;
      }
      return `${this.id}-invalid-feedback`;
    },

    invalidVisible() {
      return this.invalid && this.invalidMessage !== null;
    },

    invalid() {
      return this.state === "invalid";
    },

    valid() {
      return this.state === "valid";
    }
  },

  watch: {
    value(newVal, oldVal) {
      if (this.type !== "date" && newVal !== oldVal) {
        this.localValue = newVal;
      }
    },

    localValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
    }
  },

  methods: {
    onInput(event) {
      this.localValue = event.target.value;
    }
  }
};
</script>
