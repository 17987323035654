<template>
  <div
    class="cursor-pointer flex flex-col rounded-lg shadow-lg overflow-hidden"
    @click="handleGoToDonatePage"
  >
    <div class="flex-shrink-0">
      <img
        :src="page.background_image"
        class="h-48 w-full object-cover"
        alt="Page background image"
      />
    </div>
    <div class="flex-1 bg-white p-6 flex flex-col justify-between">
      <div class="flex-1">
        <a href="#" class="block">
          <p
            class="mt-3 text-base leading-6 text-gray-500"
            v-html="sanitize(pageContentParsed.content)"
          />
        </a>
      </div>
      <div class="mt-6">
        <a
          class="flex items-center justify-center donate-card__button donate-card__submit"
          @click="handleGoToDonatePage"
        >
          Donate
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { sanitize } from "@/helpers/html";

export default {
  name: "GalleryCard",

  props: {
    page: {
      type: Object,
      default() {
        return {};
      }
    },
    countryCode: {
      type: String,
      default: "US"
    }
  },

  data() {
    return {};
  },

  computed: {
    pageContentParsed() {
      let result;
      try {
        result = JSON.parse(this.page.contents);
      } catch {
        result = {};
      }
      return result;
    }
  },

  methods: {
    sanitize,
    handleGoToDonatePage() {
      this.$router.push({
        name: "DonatePageGalleryWithSlug",
        params: {
          code: this.countryCode,
          slug: this.page.slug
        }
      });
    }
  }
};
</script>
