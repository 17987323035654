<template>
  <select
    class="donate-card__input_half donate-card__input_half_r"
    required
    @change="$emit('input', $event.target.value)"
  >
    <option value selected disabled>Year *</option>
    <option
      v-for="year in range"
      :key="year"
      :value="year"
      :selected="year === value"
      >{{ year }}</option
    >
  </select>
</template>
<script>
import { getYear } from "date-fns";

export default {
  name: "DonateCardStepTwoSelectYear",

  props: {
    value: {
      type: Number,
      required: true
    }
  },

  computed: {
    range() {
      const year = getYear(new Date());
      return Array.from({ length: 15 }, (v, k) => k + year);
    }
  }
};
</script>
