import Vue from 'vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

const Spinner = Vue.extend(ClipLoader)

Vue.directive('loading', {
  bind(el, binding) {
    const shouldDisplay = binding && binding.value

    const spinner = new Spinner({
      el: document.createElement('div'),
      propsData: {
        size: '20px',
        color: '#ffffff',
        class: 'inline-block',
      },
    })
    spinner.$el.classList.add('hidden')

    el.spinner = spinner
    el.appendChild(spinner.$el)

    if (shouldDisplay) {
      Vue.nextTick(() => {
        el.disabled = true
        el.spinner.$el.classList.remove('hidden')
        el.spinner.$el.classList.add('inline-block')
        el.classList.toggle('opacity-50')
        el.classList.toggle('cursor-not-allowed')
      })
    }
  },
  update(el, binding) {
    if (binding.oldValue !== binding.value) {
      el.disabled = binding.value
      el.spinner.$el.classList.toggle('hidden')
      el.spinner.$el.classList.toggle('inline-block')
      el.classList.toggle('opacity-50')
      el.classList.toggle('cursor-not-allowed')
    }
  },
  unbind(el) {
    el.spinner && el.spinner.$destroy()
  },
})
