<template>
  <div>
    <section class="donate-card__info_section">
      <h3 class="mb-4">
        Donation amount: {{ currencyCode || currencySymbol }}{{ amount }}
      </h3>
      <h4>Your Information</h4>
      <input
        v-if="formFields.email"
        v-model="form.email"
        class="donate-card__input_full"
        name="email_address"
        placeholder="Email *"
        @blur="handleEmailBlur"
      >
      <div v-show="!!form.email">
        <span>{{ `Click ` }}</span>
        <a
          class="underline text-blue cursor-pointer"
          @click="handleEmailBlur"
        >here</a>
        <span>{{ ` if you have already have a PIN` }}</span>
      </div>

      <span
        v-if="showValidationErrors && formFields.email && !form.email"
        class="w-full text-sm text-red"
      >
        Email is required
      </span>
      <span
        v-show="showValidationErrors && !$v.form.personal_info.email.email"
        class="w-full text-sm text-red"
      >
        Email is invalid
      </span>

      <DonateCardStepTwoSelectTitle
        v-if="formFields.title"
        v-model="form.title"
        :form="form.title"
      />
      <span
        v-if="showValidationErrors && formFields.title && !form.title"
        class="w-full text-sm text-red"
      >
        Title is required
      </span>

      <div class="lg:grid grid-cols-2 gap-1 w-full">
        <div class="w-full">
          <input
            v-if="formFields.first_name"
            v-model="form.first_name"
            class="donate-card__input_half donate-card__input_half_l w-full"
            name="first_name"
            placeholder="First Name *"
          >
          <div class="w-full text-sm text-red">
            <span v-if="showValidationErrors && formFields.first_name && !form.first_name">
              First name is required</span>
          </div>
        </div>

        <div class="w-full">
          <input
            v-if="formFields.last_name"
            v-model="form.last_name"
            class="donate-card__input_half donate-card__input_half_r w-full"
            name="last_name"
            placeholder="Last Name *"
          >
          <div class="text-sm text-red">
            <span v-if="showValidationErrors && formFields.last_name && !form.last_name">
              Last name is required</span>
          </div>
        </div>
      </div>



      <input
        v-if="formFields.phone_number"
        v-model="form.phone"
        class="donate-card__input_full"
        name="phone"
        placeholder="Phone number"
        @keypress="isNumber($event)"
      >
    </section>

    <section
      v-if="showBillingTitle"
      class="donate-card__info_section"
    >
      <h4>Billing Address</h4>
      <input
        v-if="formFields.address"
        v-model="form.address"
        class="donate-card__input_full"
        name="address_line1"
        placeholder="Street Address *"
      >
      <span
        v-show="showValidationErrors && formFields.address && !form.address"
        class="w-full text-sm text-red"
      >
        Address is required
      </span>

      <DonateCardStepTwoSelectCountry
        v-if="formFields.country"
        v-model="form.country"
        :countries="countries"
        :first-country="firstCountry"
        class="w-full ml-0 my-3"
        name="country"
        @input="handleCountryChange"
      />
      <span
        v-show="showValidationErrors && formFields.country && !form.country"
        class="w-full text-sm text-red"
      >
        <span>Country is required</span>
      </span>

      <div class="lg:grid grid-cols-2 gap-1 w-full">
        <div class="w-full">
          <input
            v-if="formFields.city"
            v-model="form.city"
            class="donate-card__input_half donate-card__input_half_l w-full"
            name="city"
            placeholder="City *"
          >
          <div class="text-sm text-red">
            <span v-show="showValidationErrors && formFields.city && !form.city">
              City is required</span>
          </div>
        </div>
        <div class="w-full">
          <input
            v-if="formFields.zip_code"
            v-model="form.zip_code"
            class="donate-card__input_half donate-card__input_half_r w-full"
            name="zip_code"
            placeholder="Zip Code *"
          >

          <div class="text-sm text-red">
            <span v-show="showValidationErrors && formFields.zip_code && !form.zip_code">
              Zip Code is required</span>
          </div>
        </div>
      </div>

      <div
        v-if="formFields.state"
        class="w-full lg:w-1/2"
      >
        <DonateCardStepTwoSelectState
          v-if="form.country === 'US'"
          v-model="form.state"
          class="w-full ml-0"
        />

        <input
          v-else
          v-model="form.state"
          class="donate-card__input_half donate-card__input_half_r w-full mr-0"
          name="state"
          placeholder="State/Prov *"
        >

        <div
          v-show="showValidationErrors && formFields.state && !form.state"
          class="ml:0 lg:ml-1 text-sm text-red"
        >
          <span>State is required</span>
        </div>
      </div>
    </section>

    <section class="donate-card__info_section">
      <h4>Payment Information</h4>
      <div
        v-if="!!cardLast4"
        class="w-full mt-2"
      >
        <input
          id="registered-card"
          v-model="paymentMethod"
          type="radio"
          name="card-radio-button"
          value="registered"
          class="mr-2"
        >
        <label for="registered-card">Donate with default card</label>
        <div class="donate-card__input_full">
          <div class="flex items-center justify-between">
            <div class="flex">
              <div class="w-6">
                <CardIcon />
              </div>
              <div class="card-input">
                <span>{{ `**** **** **** ${cardLast4}` }}</span>
              </div>
            </div>
            <div class="mr-3">
              <span>{{
                `${String(cardExpiryMonth).padStart(2, '0')} / ${String(cardExpiryYear).slice(2)}`
              }}</span>
            </div>
          </div>
        </div>
        <input
          id="new-card"
          v-model="paymentMethod"
          type="radio"
          name="card-radio-button"
          value="new"
          class="mr-2"
        >
        <label for="new-card">Donate with new card</label>
      </div>
      <card
        :options="stripeOptions"
        :stripe="token"
        class="donate-card__input_full"
        @change="handleCardChange"
      />
    </section>

    <section class="donate-card__gdpr_communication_section">
      <div
        v-if="contents.is_gdpr_visible === '1'"
        class="donate-card__gdpr_text"
        v-html="contents.gdpr_text"
      />

      <div
        v-if="contents.is_communication_checkbox_visible === '1'"
        class="donate-card__communication_checkbox_text"
      >
        <input
          id="communication_checkbox"
          v-model="form.communication_consent"
          type="checkbox"
          name="communication_checkbox"
        >
        <label for="communication_checkbox">
          {{ contents.communication_checkbox_text }}
        </label>
      </div>
    </section>
  </div>
</template>
<script>
import DonateCardStepTwoSelectTitle from './DonateCardStepTwoSelectTitle'
import DonateCardStepTwoSelectState from './DonateCardStepTwoSelectState'
import DonateCardStepTwoSelectCountry from './DonateCardStepTwoSelectCountry'
import DonateCardStepTwoSelectMonth from './DonateCardStepTwoSelectMonth'
import DonateCardStepTwoSelectYear from './DonateCardStepTwoSelectYear'
import { Card } from 'vue-stripe-elements-plus'
import CardIcon from '@/assets/card.svg'
import { mapGetters } from 'vuex'
import _get from 'lodash.get'

const blankForm = {
  title: '',
  first_name: '',
  last_name: '',
  email: '',
  address: '',
  zip_code: '',
  city: '',
  state: '',
  country: '',
  credit_card: {
    card: '',
    month: '',
    year: '',
    cvv: '',
  },
}

export default {
  name: 'DonateCardStepTwo',

  components: {
    Card,
    DonateCardStepTwoSelectTitle,
    DonateCardStepTwoSelectState,
    DonateCardStepTwoSelectCountry,
    DonateCardStepTwoSelectMonth,
    DonateCardStepTwoSelectYear,
    CardIcon,
  },

  props: {
    $v: {
      type: Object,
      default: () => ({}),
    },
    contents: {
      type: Object,
      default: () => ({}),
    },
    showValidationErrors: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: String,
      required: true,
    },
    countries: {
      type: [Array, Object],
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    cardLast4: {
      type: String,
      required: false,
      default: null,
    },
    cardExpiryMonth: {
      type: Number,
      required: false,
      default: null,
    },
    cardExpiryYear: {
      type: Number,
      required: false,
      default: null,
    },
    firstCountry: {
      type: String,
      required: false,
      default: null,
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
    payment: {
      type: String,
      default: null,
    },
    currencyCode: {
        type: String,
      default: null,
    },
  },
  data() {
    return {
      form: this.formData || blankForm,
      stripeOptions: {
        hidePostalCode: true,
      },
    }
  },

  computed: {
    ...mapGetters(['currencySymbol']),
    paymentMethod: {
      get() {
        return this.payment
      },
      set(payment) {
        this.$emit('update:payment', payment)
      },
    },
    formFields() {
      return this.contents.donation_page_form_fields
    },
    showInfoTitle() {
      return (
        this.formFields.title ||
        this.formFields.email ||
        this.formFields.first_name ||
        this.formFields.last_name ||
        this.formFields.phone_number
      )
    },
    showBillingTitle() {
      return (
        this.formFields.address ||
        this.formFields.state ||
        this.formFields.city ||
        this.formFields.zip_code ||
        this.formFields.country
      )
    },
  },

  watch: {
    form: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      },
    },
    cardLast4: {
      handler(value) {
        this.paymentMethod = 'registered'
      },
    },
  },

  mounted() {
    this.$root.$on('update:userInfo', (val) => {
      this.form = {
        country: _get(val, 'card.address_country'),
        address: _get(val, 'card.address_line1'),
        city: _get(val, 'card.address_city'),
        state: _get(val, 'card.address_state'),
        zip_code: _get(val, 'card.address_zip'),
        title: _get(val, 'title'),
        first_name: _get(val, 'first_name'),
        last_name: _get(val, 'last_name'),
        email: _get(val, 'email'),
        phone: _get(val, 'phone'),
      }
    })
  },

  methods: {
    handleCardChange(event) {
      this.$emit('update:validCard', event.complete && !event.error)
    },
    handleEmailBlur() {
      if (!this.form.email) return
      this.$emit('email-on-blur', this.form.email)
    },
    handleCountryChange(countryCode) {
      if (countryCode === 'US') return
      this.form.state = ''
    },

    isNumber: function(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      //   if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped>
.card-input {
  margin-left: 8px;
  color: #32325d;
  line-height: 24px;
  font-family: Helvetica Neue;
}
</style>
