import http from "@/http";

export default {
  emailHasPin: email => http.post("/api/pin/is-set", { email }),
  createPin: ({ authToken, pin }) =>
    http.put(
      "/api/me/pin",
      { pin },
      {
        headers: { Authorization: `Bearer ${authToken}` }
      }
    ),
  getDonationsUserData: ({ pin, email, organization_id }) =>
    http.get("/api/pin/donation-data", {
      params: {
        pin,
        email,
        organization_id
      }
    }),
  updateUsers: ({ token, payload }) =>
    http.put(`/api/users/${token}`, payload, { shouldAuthenticate: true }),
  updatePin: ({ old_pin, pin }) =>
    http.put("/api/me/pin", { old_pin, pin }, { shouldAuthenticate: true }),
  sendEmail: email => http.post("/api/me/pin/email", { email })
};
