<template>
  <select
    class="donate-card__input_full"
    required
    @change="$emit('input', $event.target.value)"
  >
    <option value disabled selected>Select the recurring date *</option>
    <option class="select-dash" disabled="disabled">----</option>
    <option
      v-for="date in dates"
      :key="date"
      :value="date"
      :selected="date === value"
      >{{ date }}</option
    >
  </select>
</template>
<script>
export default {
  name: "DateofMonthPicker",

  props: {
    value: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    dates() {
      let result = [];

      for (let i = 1; i < 29; i++) {
        result.push(i);
      }

      return result;
    }
  }
};
</script>
