<template>
  <div class="flex flex-col px-4 md:px-2 lg:px-0">
    <div class="flex items-center justify-around">
      <div class="flex flex-col">
        <span class="text-xl">Raised</span>
        <animated-counter :number="multipliedCurrent / 100">
          <template slot-scope="{ number }">
            <span class="text-2xl font-semibold">{{
              toThousands(number)
            }}</span>
          </template>
        </animated-counter>
      </div>
      <div class="flex flex-col">
        <span class="text-xl">Goal</span>
        <span class="text-2xl font-semibold">{{ formattedGoal }}</span>
      </div>
    </div>
    <div class="mt-2 text-xl">
      <AnimatedProgressBar
        :steps="rulerSteps"
        completedColor="bg-yellow-darker text-white"
        pendingColor="bg-grey-light"
        :percentage="percentage"
      />
    </div>
  </div>
</template>

<script>
import {
  AnimatedProgressBar,
  AnimatedCounter
} from "@adra-network/vue-animatron";
export default {
  name: "DonateCardFundraising",
  props: {
    fundraising: {
      type: Object
    },
    multiplier: {
      type: Number
    }
  },
  components: {
    AnimatedProgressBar,
    AnimatedCounter
  },

  computed: {
    multipliedGoal() {
      return this.fundraising.goal * this.multiplier;
    },
    multipliedCurrent() {
      return this.fundraising.current * this.multiplier;
    },

    formattedGoal() {
      return this.toThousands(this.multipliedGoal / 100);
    },

    percentage() {
      return (this.fundraising.current * 100) / this.fundraising.goal;
    },
    rulerSteps() {
      const interval = this.multipliedGoal / 100 / 4;
      const arr = [0, 1, 2, 3, 4];
      return arr.map(o => {
        const number = parseInt(o * interval);
        return this.toThousands(number);
      });
    }
  },
  methods: {
    toThousands(value) {
      return new Intl.NumberFormat("EN", {
        notation: "compact",
        compactDisplay: "short",
        style: "currency",
        currency: "USD"
      }).format(value);
    },
    kFormatter(num) {
      return Math.abs(num) > 999
        ? new Intl.NumberFormat("EN").format(
            Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)
          ) + "k"
        : new Intl.NumberFormat("EN").format(Math.sign(num) * Math.abs(num));
    }
  }
};
</script>
