<template>
  <div>
    <div class="app-header">
      <div class="app-header__container container">
        <div class="app-header__left">
          <a class="h-full underline" href="https://adra.org/">
            <img
              src="@/assets/new-adra-logo.png"
              class="h-full w-auto cursor-pointer"
            />
          </a>
        </div>
        <div class="app-header__right">
          <div v-if="!loggedIn">
            <a class="app-header__menu-button" @click="openSigninModal"
              >Sign In</a
            >
          </div>

          <div v-if="loggedIn" class="relative flex justify-center">
            <button class="app-header__menu-button" @click="toggleUserMenu">
              {{ welcomeText }}
            </button>
            <transition name="app-header__popover">
              <ul v-show="showUserMenu" class="app-header__popover">
                <li class="app-header__popover-item" @click="toggleUserMenu">
                  <router-link :to="{ name: 'AccountSettings' }"
                    >Account Settings</router-link
                  >
                </li>
                <li class="app-header__popover-item" @click="logout">Logout</li>
              </ul>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "AppHeader",

  data() {
    return {
      showModal: false,
      showUserMenu: false
    };
  },

  computed: {
    ...mapState("auth", ["loggedIn", "showSidebar"]),
    ...mapState(["currentCountryCode"]),
    ...mapGetters("auth", ["firstName"]),
    welcomeText() {
      return this.firstName ? `Welcome ${this.firstName}` : "Welcome";
    }
  },

  methods: {
    logout() {
      this.resetAuth().then(() => {
        this.toggleUserMenu();
        this.$router.push({
          name: "DonatePage"
        });
      });
    },

    closeSidebar() {
      this.setSidebar(false);
    },

    toggleSidebar() {
      this.setSidebar(!this.showSidebar);
    },

    openSigninModal() {
      this.$emit("open-signin-modal", true);
    },

    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },

    ...mapActions({
      resetAuth: "auth/reset",
      setSidebar: "setSidebar"
    })
  }
};
</script>
