import http from "@/http";

export default {
  getDonorCards: donor => {
    return http.get(`/api/customers/${donor}/stripe-cards`, {
      shouldAuthenticate: true
    });
  },
  getDonorSubscriptions: donor => {
    return http.get(`/api/customers/${donor}/stripe-subscriptions`, {
      shouldAuthenticate: true
    });
  },
  createCard: form =>
    http.post("/api/stripe-cards", form, { shouldAuthenticate: true }),
  updateCard: (cardId, form) =>
    http.put(`/api/stripe-cards/${cardId}`, form, { shouldAuthenticate: true }),
  deleteCard: cardId =>
    http.delete(`/api/stripe-cards/${cardId}`, { shouldAuthenticate: true }),
  updateSubscription: (stripeSubscriptionId, form) =>
    http.put(`/api/stripe-subscriptions/${stripeSubscriptionId}`, form, {
      shouldAuthenticate: true
    }),
  deleteSubscription: stripeSubscriptionId =>
    http.delete(`/api/stripe-subscriptions/${stripeSubscriptionId}`, {
      shouldAuthenticate: true
    })
};
