import Vue from 'vue'
import Router from 'vue-router'
import DonatePage from '@/views/DonatePage'
import IframeDonatePage from '@/views/IframeDonatePage'
// import UserForgot from "@/views/auth/UserForgot";
// import UserLogin from "@/views/auth/UserLogin";
import UserReset from '@/views/auth/UserReset'
import AccountSettings from '@/views/AccountSettings'
import ResetPassword from '@/views/ResetPassword'
import DonationPages from '@/views/DonationPages'

import store from '@/store'

Vue.use(Router)

const authGuard = (to, from, next) => {
  if (!store.state.auth.loggedIn) {
    next({ name: 'DonatePage' })
  } else {
    next()
  }
}

const router = new Router({
  mode: 'history',

  scrollBehavior() {
    return { x: 0, y: 0 }
  },

  routes: [
    {
      path: '/:code/gallery',
      name: 'DonationPagesWithCountryCode',
      component: DonationPages,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('setReferrer', document.referrer)
        next()
      },
    },
    {
      path: '/gallery',
      name: 'DonationPagesWithoutCountryCode',
      component: DonationPages,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('setReferrer', document.referrer)
        next()
      },
    },
    {
      path: '/reset/:token',
      name: 'UserReset',
      component: UserReset,
    },
    {
      path: '/account-settings',
      name: 'AccountSettings',
      component: AccountSettings,
      beforeEnter: async (to, from, next) => {
        store.dispatch('setReferrer', document.referrer)
        if (to.query.state) {
          await store.dispatch('auth/setToken', to.query.state)
        }
        authGuard(to, from, next)
      },
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
      props: route => ({ email: route.query.email, token: route.query.token }),
      beforeEnter: (to, from, next) => {
        store.dispatch('setReferrer', document.referrer)
        next()
      },
    },
    {
      path: '/iframe/:slug?',
      name: 'iframe-embed',
      component: IframeDonatePage,
    },
    {
      path: '/:code',
      name: 'DonatePageWithoutSlug',
      component: DonatePage,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('setReferrer', document.referrer)
        next()
      },
    },

    {
      path: '/:slug?',
      name: 'DonatePage',
      component: DonatePage,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('setReferrer', document.referrer)
        next()
      },
    },
    {
      path: '/:code/:slug',
      name: 'DonatePageWithSlug',
      component: DonatePage,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('setReferrer', document.referrer)
        next()
      },
    },

    {
      path: '/:code/gallery/:slug',
      name: 'DonatePageGalleryWithSlug',
      component: DonatePage,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('setReferrer', document.referrer)
        next()
      },
    },
    {
      path: '*',
      name: 'DonatePage',
      component: DonatePage,
      beforeEnter: (to, from, next) => {
        store.dispatch('setReferrer', document.referrer)
        next()
      },
    },
  ],
})

export default router
