<template>
  <form class="px-10">
    <div>
      <div>
        <div class="mt-6 sm:mt-5">
          <div
            class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5"
          >
            <label
              for="password"
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Old Password
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-xs flex shadow">
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  class="flex-1 form-input block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
          </div>

          <div
            class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5"
          >
            <label
              for="new_password"
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              New Password
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-xs flex shadow">
                <input
                  id="new_password"
                  v-model="new_password"
                  type="password"
                  class="flex-1 form-input block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
          </div>

          <div
            class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5"
          >
            <label
              for="confirm_password"
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Confirm New Password
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-xs flex shadow">
                <input
                  id="confirm_password"
                  v-model.trim="$v.new_password_confirmation.$model"
                  type="password"
                  class="flex-1 form-input block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              <p
                v-if="!$v.new_password_confirmation.sameAsPassword"
                class="invalid-feedback"
              >
                Passwords must be identical.
              </p>
            </div>
          </div>

          <div class="flex justify-end">
            <span class="inline-flex rounded-md shadow-sm">
              <button
                v-loading="isLoading"
                class="button button-sm button-green"
                @click.prevent="handleSubmit"
              >
                Save
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import AuthService from "@/api/auth";
import _get from "lodash.get";
import { mapActions, mapState } from "vuex";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: "ResetPassword",

  data() {
    return {
      new_password: null,
      password: null,
      new_password_confirmation: null,
      isLoading: false
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },

  methods: {
    ...mapActions("auth", ["setUser"]),
    async handleSubmit() {
      this.isLoading = true;

      try {
        const updatedResponse = await AuthService.changeResetPassword(
          this.user.token,
          {
            new_password: this.new_password,
            password: this.password,
            new_password_confirmation: this.new_password_confirmation
          }
        );

        this.$toasted.success(
          "We've successfully saved your password for future purchases.",
          {
            duration: 4000
          }
        );
        this.new_password = null;
        this.password = null;
        this.new_password_confirmation = null;
      } catch (ex) {
        this.$toasted.error(
          _get(ex, "data.error", "There was an error while updating."),
          {
            duration: 8000
          }
        );
      }
      this.isLoading = false;
    }
  },

  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    new_password: {
      required,
      minLength: minLength(6)
    },

    new_password_confirmation: {
      sameAsPassword: sameAs("new_password")
    }
  }
};
</script>
