<template>
  <div class="w-4/5 flex items-center justify-center flex-col mx-auto">
    <ErrorImg
      class="mb-8"
    />
    <p>This page does not exist</p>
  </div>
</template>

<script>
import ErrorImg from '@/assets/page_not_found.svg'
export default {
  name: 'ErrorPage',

  components: {
    ErrorImg,
  },
}
</script>
