<template>
  <div class="donate-card">
    <DonateCardFundraising
      v-if="hasFundraising"
      :fundraising="fundraising"
      :multiplier="multiplier"
    />
    <div class="flex flex-row items-center justify-between w-full mb-4">
      <a
        v-show="step > 1"
        href="#"
        class="donate-card__back"
        @click="back"
      >&lt; Back</a>
    </div>

    <DonateCardStepOne
      v-if="step === 1"
      v-model="form.amount"
      :is-blackbaud-active="isBlackbaudActive"
      :blackbaud-url="page.blackbaud_url"
      :contents="page.contents"
      :currency-code="page.organization.currency_code || page.currency_code"
      :currency-on-right="page.currency_on_right"
      :payment-type.sync="form.payment_type"
      :currencies="page.currencies"
      :external-support-slugs="page.external_support_slugs"
      @back="back"
      @input="$v.$touch()"
    />
    <span
      v-if="isValidationErrorsVisible && !$v.form.amount.required"
      class="text-sm text-red"
    >An amount is required</span>

    <DonateCardStepTwo
      v-else-if="step === 2"
      :contents="page.contents"
      :form-data="form.personal_info"
      :amount="form.amount"
      :countries="page.countries"
      :first-country="page.country_code"
      :token="page.stripe_pk"
      :valid-card.sync="form.valid_card"
      :$v="$v"
      :card-last4="cardLast4"
      :card-expiry-month="cardExpiryMonth"
      :card-expiry-year="cardExpiryYear"
      :show-validation-errors="isValidationErrorsVisible"
      :payment.sync="paymentMethod"
      :currency-code="currency.symbol"
      @back="back"
      @input="(updatedInfo) => {form.personal_info = updatedInfo}"
      @email-on-blur="checkEmailPin"
    />
    <DonateCardSuccess
      v-if="step === 3"
      :class="{ 'mb-6': countdown }"
      :content="page.contents.donation_confirmation"
      :donation="donation"
      :should-create-pin="shouldCreatePin"
      :currency-code="currency.symbol"
    />
    <div v-if="step !== 3">
      <p
        v-if="error"
        class="mb-1 text-center text-red txt-xl"
      >
        {{ error }}
      </p>
      <button
        v-if="!isBlackbaudActive"
        :class="{ 'opacity-50 cursor-not-allowed': loading }"
        class="donate-card__button donate-card__submit"
        @click="nextStep"
      >
        <span v-if="!loading">Donate</span>
        <span v-else>
          <pulse-loader
            :loading="loading"
            color="#ffffff"
          />
        </span>
      </button>
      <p class="text-sm">
        {{ page.contents.did_you_know }}
      </p>
    </div>
    <DonateCardCountdown
      v-if="countdown"
      class="mt-2"
      :countdown="countdown"
    />

    <BaseModal
      v-if="showValidatePinForm"
      :user-info.sync="userInfo"
      sm
      @close="showValidatePinForm = false"
    >
      <ValidatePinForm
        slot="body"
        :email="form.personal_info.email"
        :organization-id="organizationId"
      />
    </BaseModal>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { createToken } from 'vue-stripe-elements-plus'
import DonateCardFundraising from './DonateCardFundraising'
import DonateCardCountdown from './DonateCardCountdown'
import DonateCardStepOne from './DonateCardStepOne'
import DonateCardStepTwo from './DonateCardStepTwo'
import DonateCardSuccess from './DonateCardSuccess'
import ValidatePinForm from './ValidatePinForm'
import DonationService from '@/api/DonationService'
import PaymentService from '@/api/PaymentService'
import UserService from '@/api/UserService'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import _get from 'lodash/get'
import { mapGetters } from 'vuex'

const dataLayer = (window.dataLayer = window.dataLayer || [])

export default {
  components: {
    DonateCardFundraising,
    DonateCardStepOne,
    DonateCardStepTwo,
    DonateCardSuccess,
    PulseLoader,
    ValidatePinForm,
    DonateCardCountdown,
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      fundraising: this.page.fundraising,
      donation: null,
      error: null,
      loading: false,
      isValidationErrorsVisible: false,
      step: 1,
      showValidatePinForm: false,
      shouldCreatePin: false,
      form: {
        amount: '',
        ohoohdasopayment_type: 'one-time',
        valid_card: false,
        token: '',
        personal_info: {
          title: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          communication_consent: false,
          address: '',
          zip_code: '',
          city: '',
          state: '',
          country: '',
          credit_card: {
            card: '',
            month: '',
            year: '',
            cvv: '',
          },
        },
      },
      userInfo: null,
      paymentMethod: null,
      currency: {},
    }
  },

  computed: {
    ...mapGetters(['currencySymbol']),
    organizationId() {
      return this.page && this.page.organization && this.page.organization.id
    },
    cardId() {
      return _get(this.userInfo, 'card.id')
    },
    cardFingerPrint() {
      return _get(this.userInfo, 'card.fingerprint')
    },
    cardLast4() {
      return _get(this.userInfo, 'card.last4')
    },
    cardExpiryMonth() {
      return _get(this.userInfo, 'card.exp_month')
    },
    cardExpiryYear() {
      return _get(this.userInfo, 'card.exp_year')
    },
    formFields() {
      return this.page.contents.donation_page_form_fields
    },
    isBlackbaudActive() {
      return this.form.payment_type === 'monthly' && !!this.page.blackbaud_url
    },

    countdown() {
      if (!this.page.countdown_date) {
        return false
      }
      const dateArray = this.page.countdown_date.split('-')
      // -1 to offet decemebr to actual real month (January  =0 )
      const count = new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
      return count.getTime() - new Date().getTime()
    },
    multiplier() {
      return this.page.contents.multiplier
        ? Number(this.page.contents.multiplier)
        : 1
    },

    hasFundraising() {
      return this.fundraising && this.fundraising.goal !== null
    },
  },

  created() {
    this.setAmountOverride()
    this.currency.code = 'USD'
  },
  methods: {
    setAmountOverride() {
      const amount = parseFloat(this.$route.query['amount'])

      if (!amount || !Number.isInteger(amount)) {
        return
      }
      return (this.form.amount = amount)
    },
    back() {
      this.step = this.step > 1 ? this.step - 1 : 1
      this.$v.$touch()
    },

    async donate() {
      this.error = null
      let data = null
      if (this.paymentMethod !== 'registered') {
        try {
          data = await createToken({
            name: `${this.form.personal_info.first_name} ${this.form.personal_info.last_name}`,
            address_line1: this.form.personal_info.address,
            address_city: this.form.personal_info.city,
            address_state: this.form.personal_info.state,
            address_zip: this.form.personal_info.zip_code,
            address_country: this.form.personal_info.country,
            currency: this.currencySymbol,
            country: this.page.organization.country_code,
          })
        } catch {
          this.error = 'There was a problem verifying your card.'
          return
        }
      }

      try {
        const blockData = await PaymentService.logAttempt()
        if (_get(blockData, 'data.data.block_clears_at')) {
          this.loading = false
          this.error = 'You have exceeded donation payment attempt threshold.'
          return
        }
      } catch {
        this.error = 'There was an internal error.'
        return
      }

      let formData = {
        donation_page_variation_slug: this.page.slug,
        stripe: {
          amount: parseFloat(this.form.amount) * 100,
          is_monthly: this.form.payment_type === 'monthly',
          token: this.paymentMethod === 'registered' ? null : data.token.id,
          currency: this.currencySymbol,
        },
        donor: {
          title: this.form.personal_info.title,
          first_name: this.form.personal_info.first_name,
          last_name: this.form.personal_info.last_name,
          email: this.form.personal_info.email,
          phone: this.form.personal_info.phone,
          communication_consent: this.form.personal_info.communication_consent,
          timezone: this.page.location.timezone,
          billing_address: {
            address_line1: this.form.personal_info.address,
            city: this.form.personal_info.city,
            region: this.form.personal_info.state,
            postal_code: this.form.personal_info.zip_code,
            country: this.form.personal_info.country,
          },
        },
        package_id: _get(this.page, 'package.id'),
      }

      DonationService.donate(formData)
        .then(({ data }) => {
          if (!data.success) {
            return
          }
          this.donation = data.data
          // Increment the fundraising component value
          const donatedAmount = parseInt(this.form.amount) * 100
          this.fundraising.current += donatedAmount
          //

          const monthly = formData.stripe.is_monthly ? 'M' : ''

          dataLayer.push({
            event: 'donationComplete',
            transactionId: this.donation.order_number,
            transactionAffiliation: 'ADRA',
            transactionTotal: this.form.amount,
            transactionTax: 0,
            transactionShipping: 0,
            transactionProducts: [
              {
                sku: `${this.donation.template.code}${monthly}${this.donation.appeal.code}`,
                name: this.donation.appeal.name,
                category: formData.stripe.is_monthly ? 'Monthly' : 'Individual',
                price: this.donation.amount,
                quantity: 1,
              },
            ],
          })
          this.loading = false
          this.step = 3

          const hasPin = _get(this.donation, 'pin_set', false)

          if (!hasPin) {
            this.shouldCreatePin = true
          }
        })
        .catch(error => {
          this.error =
            (error && error.data && error.data.message) ||
            'There was a problem processing your donation.'
          this.loading = false
        })
    },

    nextStep() {
      // if this step has error, display them on submit
      if (this.$v.$invalid) {
        return (this.isValidationErrorsVisible = true)
      }
      // if not errors for this step, hide the next steps errors until submit
      this.isValidationErrorsVisible = false
      if (this.step < 2) {
        this.step = 2
      } else {
        this.loading = true
        this.donate()
      }
    },

    async checkEmailPin(email) {
      let hasPin
      let checkPinResult
      try {
        checkPinResult = await UserService.emailHasPin(email)
        hasPin = _get(checkPinResult, 'data.data.pin_set', false)
      } catch (ex) {
        hasPin = false
      }

      if (hasPin) {
        this.showValidatePinForm = true
        setTimeout(() => {
          document.getElementById('pin').focus()
        }, 100)
      }
    },
  },

  validations() {
    let form = {
      amount: {
        required,
      },
    }

    if (this.step === 2) {
      form = Object.assign(form, {
        valid_card(value) {
          return value.valid_card || this.paymentMethod === 'registered'
        },
      })

      let personal_info = {}

      if (this.formFields.title) {
        personal_info.title = { required }
      }
      if (this.formFields.email) {
        personal_info.email = { required, email }
      }
      if (this.formFields.last_name) {
        personal_info.last_name = { required }
      }
      if (this.formFields.first_name) {
        personal_info.first_name = { required }
      }
      if (this.formFields.address) {
        personal_info.address = { required }
      }
      if (this.formFields.zip_code) {
        personal_info.zip_code = { required }
      }
      if (this.formFields.city) {
        personal_info.city = { required }
      }
      if (this.formFields.country) {
        personal_info.country = { required }
      }
      if (this.formFields.state && this.form.personal_info.country === 'US') {
        personal_info.state = { required }
      }

      form.personal_info = personal_info
    }
    return { form }
  },
}
</script>

<style scoped>
.flag-image {
  @apply w-auto h-8;
}
</style>
