<template>
  <form class="px-10">
    <div>
      <div>
        <div class="mt-6 sm:mt-5">
          <div
            class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5"
          >
            <label
              for="title"
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Title
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-sm flex shadow">
                <select
                  v-model="form.title"
                  class="flex-1 form-input shadow block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  name="title"
                >
                  <option value="" disabled>Title *</option>
                  <option value="Mr">Mr.</option>
                  <option value="Mrs">Mrs.</option>
                  <option value="Ms">Ms.</option>
                  <option value="Dr">Dr.</option>
                  <option value="Pastor">Pastor</option>
                  <option value="None">None</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5"
          >
            <label
              for="firstname"
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              First Name
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-sm flex shadow">
                <input
                  id="firstname"
                  v-model="form.first_name"
                  class="flex-1 form-input block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
          </div>

          <div
            class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5"
          >
            <label
              for="lastname"
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Last Name
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-sm flex shadow">
                <input
                  id="lastname"
                  v-model="form.last_name"
                  class="flex-1 form-input block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
          </div>

          <!-- <div class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5">
            <label 
              for="email" 
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
              Email
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-sm flex shadow">
                <input 
                  id="email" 
                  v-model="form.email"
                  class="flex-1 form-input block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5">
              </div>
            </div>
          </div> -->

          <div
            class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5"
          >
            <label
              for="phone"
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Phone
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="max-w-sm flex shadow">
                <input
                  id="phone"
                  v-model="form.phone"
                  class="flex-1 form-input block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  @keypress="isNumber($event)"
                />
              </div>
            </div>
          </div>

          <div class="flex justify-end">
            <span class="inline-flex rounded-md shadow-sm">
              <button
                v-loading="isLoading"
                class="button button-sm button-green"
                @click.prevent="handleSubmit"
              >
                Save
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import UserService from "@/api/UserService";
import _get from "lodash.get";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserForm",

  data() {
    return {
      form: {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        title: null
      },
      isLoading: false
    };
  },

  computed: {
    ...mapGetters("auth", ["user", "authToken"])
  },

  created() {
    this.setForm(this.user);
  },

  methods: {
    ...mapActions("auth", ["setUser"]),
    async handleSubmit() {
      this.isLoading = true;
      try {
        const updatedResponse = await UserService.updateUsers({
          token: this.user.token,
          payload: this.form
        });

        const updatedUser = _get(updatedResponse, "data.data");
        this.setForm(updatedUser);
        this.setUser({
          token: this.authToken,
          user: updatedUser
        });

        this.$toasted.success("We've successfully saved your profile.", {
          duration: 4000
        });
      } catch (ex) {
        this.$toasted.error(
          _get(ex, "data.error", "There was an error while updating."),
          {
            duration: 8000
          }
        );
      }
      this.isLoading = false;
    },

    setForm(user) {
      this.form.email = _get(user, "email");
      this.form.first_name = _get(user, "first_name");
      this.form.last_name = _get(user, "last_name");
      this.form.phone = _get(user, "phone");
      this.form.title = _get(user, "title");
    },

    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
