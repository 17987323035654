<template>
  <div class="auth">
    <form class="auth__form" @submit.prevent="submitForgot">
      <div class="auth__form__section auth__header">
        <!-- <RocketIcon class="auth__icon" /> -->
        <h1 class="auth__title">Can't remember?</h1>
        <h4 class="auth__subtitle">Enter your email to reset your password.</h4>
      </div>
      <hr class="auth__hr" />
      <div v-if="!submitted" class="auth__form__section">
        <R64Input
          id="identifier"
          v-model="forgotForm.email"
          :state="inputState($v.forgotForm.email)"
          placeholder="Email address"
          invalid-message="Email is required"
          @blur="$v.forgotForm.email.$touch()"
        />

        <p v-if="serverValidationError" class="invalid-feedback">
          {{ serverValidationError }}
        </p>

        <div class="auth__buttons">
          <button
            class="auth__buttons__link"
            type="button"
            @click.self="openSignin"
          >
            Remember it now?
          </button>
          <button
            v-loading="isLoading"
            class="button button-green"
            type="submit"
          >
            Send Email
          </button>
        </div>
      </div>
      <div v-else class="auth__form__section">
        <p>We've sent a reset link to your email!</p>
      </div>
    </form>
  </div>
</template>

<script>
import AuthAPI from "@/api/auth";
import R64Input from "@/components/r64/R64Input";
import { email, required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("auth");

export default {
  name: "UserForgot",

  components: {
    R64Input
  },

  data() {
    return {
      forgotForm: {
        email: ""
      },
      serverValidationError: null,
      submitted: false,
      isLoading: false
    };
  },

  mounted() {
    this.reset();
  },

  methods: {
    ...mapActions(["reset"]),
    submitForgot() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isLoading = true;
      AuthAPI.forgotPassword(this.forgotForm.email)
        .then(() => {
          this.serverValidationError = null;
          this.submitted = true;
          this.isLoading = false;
        })
        .catch(({ data: errorResult }) => {
          this.submitted = false;
          this.serverValidationError = errorResult.error;
          this.isLoading = false;
        });
    },
    openSignin() {
      this.$parent.$emit("open-signin");
    }
  },

  validations: {
    forgotForm: {
      email: {
        email,
        required
      }
    }
  }
};
</script>
