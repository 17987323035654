import types from './mutation-types'

export default {
  reset() {},

  setSidebar({ commit }, sidebar) {
    commit(types.updateSidebar, sidebar)
  },

  setReferrer({ commit }, referrer) {
    commit(types.setReferrer, referrer)
  },

  setCurrentCountryCode({ commit }, countryCode) {
    commit(types.setCurrentCountryCode, countryCode)
  },
  setCurrentCurrency({ commit }, currency) {
    commit(types.setCurrentCurrency, currency)
  },
}
