<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="mb-4">
        <label
          >Would you like to set a PIN to make future purchases faster?</label
        >
      </div>
      <div class="mb-4">
        <R64Input
          id="pin"
          v-model="form.pin"
          :state="inputState($v.form.pin)"
          placeholder="Please type your new PIN here"
          invalid-message="PIN is required"
          type="password"
          @blur="$v.form.pin.$touch()"
        />
      </div>

      <div class>
        <button
          :class="{ 'opacity-50 cursor-not-allowed': loading }"
          class="donate-card__button donate-card__submit"
          type="submit"
        >
          <span v-if="!loading">Save</span>
          <span v-else>
            <pulse-loader :loading="loading" color="#ffffff" />
          </span>
        </button>
      </div>

      <div v-if="serverValidationError" class="flex items-center">
        <p class="invalid-feedback">{{ serverValidationError }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import R64Input from "@/components/r64/R64Input";
import { required } from "vuelidate/lib/validators";
import UserService from "@/api/UserService";
import _get from "lodash.get";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "CreateNewPinForm",

  components: {
    R64Input,
    PulseLoader
  },

  props: {
    userToken: {
      type: String,
      default: null
    },
    authToken: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      form: {
        pin: null
      },
      loading: false,
      serverValidationError: null
    };
  },

  methods: {
    async submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let isSuccess;
      let errorMessage;
      this.loading = true;
      try {
        const result = await UserService.createPin({
          pin: this.form.pin,
          authToken: this.authToken
        });
        isSuccess = _get(result, "data.success");
      } catch (ex) {
        errorMessage = ex && ex.data && ex.data.error;
        console.log(errorMessage);
        this.serverValidationError = `There was an error saving the PIN. ${errorMessage}`;
      }

      this.loading = false;
      if (isSuccess) {
        this.$parent.$emit("close");
        this.$emit("saved");
        this.$toasted.success(
          "We've successfully saved your PIN for future purchases.",
          {
            duration: 4000
          }
        );
      } else {
        this.serverValidationError = `There was an error saving the PIN. ${errorMessage}`;
      }
    }
  },

  validations: {
    form: {
      pin: {
        required
      }
    }
  }
};
</script>
