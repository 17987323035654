<template>
  <div class="auth">
    <div class="bg-white shadow-md rounded px-8 pt-6 pb-8">
      <div class="mb-4">
        <h4>Please enter your PIN to retrieve your details</h4>
      </div>
      <div class="mb-4">
        <R64Input
          id="pin"
          v-model="form.pin"
          :state="inputState($v.form.pin)"
          placeholder="PIN"
          invalid-message="PIN is required"
          type="password"
          @keyup.native.enter="submitForm"
        />
      </div>

      <div class="flex flex-col items-center mb-4">
        <button
          :class="{ 'opacity-50 cursor-not-allowed': loading }"
          class="button button-green button-sm w-full flex justify-center"
          @click="submitForm"
        >
          <span v-if="!loading">Submit</span>
          <span v-else>
            <pulse-loader
              :loading="loading"
              color="#ffffff"
            />
          </span>
        </button>
        <button
          :class="{ 'opacity-50 cursor-not-allowed': buttonLoading }"
          class="button button-outline button-xs mt-2 w-full flex justify-center"
          type="submit"
          @click="sendEmail"
        >
          <span v-if="!buttonLoading">Email me my pin</span>
          <span v-else>
            <pulse-loader
              :loading="buttonLoading"
              color="#007B5F"
            />
          </span>
        </button>
      </div>

      <div class="flex items-center">
        <p
          v-if="serverValidationError"
          class="invalid-feedback"
        >
          {{ serverValidationError }}
        </p>
        <p
          v-if="emailValidation"
          class="valid-feedback"
        >
          {{ emailValidation }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import R64Input from '@/components/r64/R64Input'
import { required } from 'vuelidate/lib/validators'
import UserService from '@/api/UserService'
import _get from 'lodash.get'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'ValidatePinForm',

  components: {
    R64Input,
    PulseLoader,
  },

  props: {
    email: {
      type: String,
      default: null,
    },
    organizationId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      form: {
        pin: null,
      },
      loading: false,
      buttonLoading: false,
      serverValidationError: null,
      emailValidation: null,
    }
  },

  methods: {
    async submitForm() {
      this.emailValidation = null
      this.serverValidationError = null
      this.$v.form.pin.$touch()
      if (this.$v.$invalid) return
      let result
      this.loading = true
      try {
        result = await UserService.getDonationsUserData({
          pin: this.form.pin,
          email: this.email,
          organization_id: this.organizationId,
        })
        const userInfo = _get(result, 'data.data')
        this.$root.$emit('update:userInfo', userInfo)
        this.$parent.$emit('update:userInfo', userInfo)
        this.$parent.$emit('close')
      } catch (ex) {
        this.serverValidationError =
          'There was an error while getting your information. Please fill out the form manually.'
      }
      this.loading = false
    },

    async sendEmail() {
      this.emailValidation = null
      this.serverValidationError = null
      this.$v.$reset()
      this.buttonLoading = true
      try {
        await UserService.sendEmail(this.email)
        this.emailValidation = 'An email has been sent with your pin'
      } catch (ex) {
        this.serverValidationError =
          'There was an error while sending your email. Please try again later'
      }
      this.buttonLoading = false
    },
  },

  validations: {
    form: {
      pin: {
        required,
      },
    },
  },
}
</script>
