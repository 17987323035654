<template>
  <div>
    <div
      v-if="loading"
      class="preloader"
    >
      <pulse-loader
        :loading="loading"
        :color="`#${$route.query['loader-color'] || '#007B5F'}`"
        class=""
      />
    </div>
    <div
      v-else
      class="px-4 md:px-12 "
    >
      <DonateCardFundraising
        class="w-full "
        :style="`color: #${$route.query['text-color'] || '#000'}`"
        :fundraising="donationPage.fundraising"
        :multiplier="donationPage.multiplier"
      />
      <DonateCardCountdown
        v-if="countdown"
        :style="`color: #${$route.query['text-color'] || '#000'}`"
        class="mt-4"
        :countdown="countdown"
      />
    </div>
  </div>
</template>

<script>
import { initAnalytics } from '../social'
import PageService from '../api/PageService'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import DonateCardFundraising from '@/components/DonatePage/DonateCardFundraising'
import DonateCardCountdown from '@/components/DonatePage/DonateCardCountdown'

export default {
  name: 'IframeDonatePage',

  components: {
    DonateCardFundraising,
    DonateCardCountdown,
    PulseLoader,
  },

  data() {
    return {
      loading: true,
      donationPage: {},
    }
  },
  computed: {
    countdown() {
      if (
        this.$route.query['show-countdown'] !== 'true' ||
        !this.donationPage.contents.countdown_at
      ) {
        return false
      }
      const dateArray = this.donationPage.countdown_date.split('-')
      // -1 to offet decemebr to actual real month (January  =0 )
      const count = new Date(dateArray[0], dateArray[1] - 1, dateArray[2])
      return count.getTime() - new Date().getTime()
    },

    slug() {
      return this.$route.params.slug ? this.$route.params.slug : null
    },
    code() {
      return this.$route.params.code ? this.$route.params.code : 'US'
    },
  },
  async created() {
    let result

    if (this.slug) {
      result = await PageService.getDonationPageBySlug(
        this.code.toUpperCase(),
        this.slug,
      )
    } else {
      result = await PageService.getDefaultPageByOrganization()
    }
    this.initializePage(result.data.data)
  },

  methods: {
    initializePage(data) {
      this.donationPage.fundraising = data.fundraising
      this.donationPage.countdown_date = data.countdown_date
      this.donationPage.contents = JSON.parse(data.contents)
      this.donationPage.multiplier = this.donationPage.contents.multiplier
        ? Number(this.donationPage.contents.multiplier)
        : 1
      this.loading = false
    },
  },
}
</script>

<style>
body {
  @apply bg-transparent  !important;
}
.preloader {
  @apply flex flex-col items-center justify-center w-full absolute h-screen top-0 left-0;
  transition: opacity 0.3s linear;
  background: rgba(0, 0, 0, 0.3);
}
</style>
