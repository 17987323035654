<template>
  <content-loader
    :height="70"
    :width="400"
    :speed="2"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect x="7" y="5" rx="3" ry="3" width="70" height="8" />
    <rect x="7" y="27" rx="3" ry="3" width="370" height="8" />
    <rect x="7" y="47" rx="3" ry="3" width="370" height="8" />
  </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  name: "TableLoader",
  components: {
    ContentLoader
  }
};
</script>
