<template>
  <div class="auth">
    <form class="auth__form" @submit.prevent="submitChangePassword">
      <div class="auth__form__section auth__header">
        <h1 class="auth__title">Change Password</h1>
        <h4 class="auth__subtitle">Enter your new password.</h4>
      </div>
      <hr class="auth__hr" />
      <div class="auth__form__section">
        <R64Input
          id="password"
          v-model="form.password"
          :state="inputState($v.form.password)"
          type="password"
          placeholder="Password"
          invalid-message="Password is required and must have at least 6 characters."
          @blur="$v.form.password.$touch()"
        />

        <R64Input
          id="passwordRetype"
          v-model="form.passwordRetype"
          :state="inputState($v.form.passwordRetype)"
          type="password"
          placeholder="Retype Password"
          invalid-message="Password does not match."
          @blur="$v.form.passwordRetype.$touch()"
        />

        <p v-if="serverValidationError" class="invalid-feedback">
          {{ serverValidationError }}
        </p>

        <div class="auth__buttons">
          <button
            v-loading="isLoading"
            class="button button-green"
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AuthAPI from "@/api/auth";
import R64Input from "@/components/r64/R64Input";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("auth");

export default {
  name: "UserChangePassword",

  components: {
    R64Input
  },

  props: {
    email: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      form: {
        password: "",
        passwordRetype: ""
      },
      serverValidationError: null,
      isLoading: false
    };
  },

  methods: {
    ...mapActions(["setUser"]),
    submitChangePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isLoading = true;
      AuthAPI.changePassword({
        email: this.email,
        password: this.form.password,
        password_confirmation: this.form.passwordRetype,
        token: this.token
      })
        .then(response => {
          this.serverValidationError = null;
          this.setUser({
            token: response.data.data.token,
            user: response.data.data.user
          });
          this.$parent.$emit("password-changed");
          this.isLoading = false;
        })
        .catch(({ data: errorResult }) => {
          this.serverValidationError = errorResult.error;
          this.isLoading = false;
        });
    }
  },

  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      passwordRetype: {
        sameAsPassword: sameAs("password")
      }
    }
  }
};
</script>
