<template>
  <div v-show="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseTab",

  props: {
    name: {
      required: true,
      type: String
    },
    selected: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isActive: false
    };
  },

  mounted() {
    this.isActive = this.selected;
  }
};
</script>
