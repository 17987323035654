import http from "@/http";

export default {
  url: "/api/auth",

  login(credentials) {
    return http.post(this.url + "/login", credentials);
  },

  forgotPassword(email) {
    return http.post(this.url + "/password/email", { email });
  },

  getToken(email) {
    return http.post(this.url + "/password/token", { email });
  },

  changePassword(payload) {
    return http.post(this.url + "/password/reset", payload);
  },

  changeResetPassword(userToken, payload) {
    return http.put("/api/users/" + userToken, payload, {
      shouldAuthenticate: true
    });
  },

  validateChangePasswordToken(payload) {
    return http.get(this.url + "/password/validate-token", {
      params: payload
    });
  },

  resetPassword({ email, password, password_confirmation, token }) {
    return http.post(this.url + "/password/reset", {
      email,
      password,
      password_confirmation,
      token
    });
  },

  refresh() {
    return http.post(this.url + "/refresh");
  },

  user() {
    return http.get("/api/user", {
      shouldAuthenticate: true
    });
  }
};
