<template>
  <div class="auth">
    <form
      class="bg-white shadow-md rounded px-8 pt-6 pb-8"
      @submit.prevent="submitForm"
    >
      <div class="mb-4">
        <h4>Update Subscription</h4>
      </div>
      <div>
        <section class="donate-card__info_section">
          <h4>Subscription Information</h4>

          <div>
            <label class="mb-3 block">Amount</label>
            <DonateCardStepOneAmount
              :currency-on-right="false"
              v-model="form.amount"
            />
          </div>

          <div>
            <label>Day of month charge is processed</label>
            <DateOfMonthPicker v-model.number="form.date" />
          </div>
        </section>

        <section class="donate-card__info_section">
          <h4>Card Information</h4>

          <CardPicker v-model="form.stripe_card_id" :cards="cards" />
        </section>
      </div>

      <p v-if="errors" class="invalid-feedback">{{ errors }}</p>
      <div class="flex items-center justify-between">
        <button
          v-loading="isLoading"
          class="button button-green button-sm"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import _get from "lodash.get";
import _toString from "lodash/toString";
import CardPicker from "./CardPicker";
import DonateCardStepOneAmount from "@/components/DonatePage/DonateCardStepOneAmount";
import DateOfMonthPicker from "./DateOfMonthPicker";
import getDate from "date-fns/getDate";

export default {
  name: "SubscriptionForm",

  components: {
    CardPicker,
    DonateCardStepOneAmount,
    DateOfMonthPicker
  },

  props: {
    subscription: {
      type: Object,
      default() {
        return null;
      }
    },
    cards: {
      type: Array,
      default() {
        return [];
      }
    },
    errors: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: {
        organization_id: process.env.VUE_APP_ORGANIZATION_ID,
        amount: null,
        date: null,
        stripe_card_id: null
      }
    };
  },

  watch: {
    subscription: {
      handler(newSubscription) {
        this.initForm(newSubscription);
      },
      immediate: true
    }
  },

  methods: {
    initForm(newSubscription) {
      this.form.amount = _get(newSubscription, "amount", "").toString();
      this.form.date = Number(
        getDate(new Date(_get(this.subscription, "billing_cycle_anchor")))
      );
      this.form.stripe_card_id =
        _get(this.subscription, "stripe_card_id") || "";
    },
    submitForm() {
      let newForm = { ...this.form };
      newForm.amount = _toString(parseFloat(newForm.amount) * 100);
      this.$parent.$emit("submit", newForm);
    }
  }
};
</script>
