<template>
  <select
    v-model="localValue"
    class="donate-card__input_full"
    name="title"
    required
  >
    <option value="" disabled>Title *</option>
    <option value="Mr">Mr.</option>
    <option value="Mrs">Mrs.</option>
    <option value="Ms">Ms.</option>
    <option value="Dr">Dr.</option>
    <option value="Pastor">Pastor</option>
    <option value="None">None</option>
  </select>
</template>
<script>
export default {
  name: "DonateCardStepTwoSelectTitle",

  props: {
    value: {
      type: String,
      required: false,
      default: ""
    }
  },

  computed: {
    localValue: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      }
    }
  }
};
</script>
