<template>
  <transition name="base-modal">
    <div class="base-modal">
      <div
        :class="{
          'base-modal__wrapper--sm': sm
        }"
        class="base-modal__wrapper"
      >
        <div class="base-modal__container">
          <div class="base-modal__header">
            <slot name="header">
              <span class="base-modal__header-icon" @click="closeModal">
                <DarkCloseIcon />
              </span>
            </slot>
          </div>

          <div class="base-modal__body">
            <slot name="body" />
          </div>

          <div class="base-modal__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DarkCloseIcon from "@/assets/i-close-dark.svg";

export default {
  name: "BaseModal",

  components: {
    DarkCloseIcon
  },

  props: {
    sm: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>
