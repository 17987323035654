<template>
  <div
    :class="cardAlignment"
    class="donate-page"
  >
    <DonateCard
      v-if="!loading && !showError"
      :page="donationPage"
    />
    <div
      v-if="loading"
      class="preloader"
    >
      <img
        src="@/assets/new-adra-logo.png"
        class="object-contain w-64 h-auto mb-8"
      >
      <pulse-loader
        :loading="loading"
        color="#007B5F"
        class=""
      />
    </div>
    <ErrorPage v-if="showError" />
  </div>
</template>

<script>
import { initAnalytics } from '../social'
import DonateCard from '@/components/DonatePage/DonateCard'
import PageService from '../api/PageService'
import { mapActions } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import ErrorPage from '@/components/ErrorPage'

export default {
  name: 'DonatePage',

  components: {
    DonateCard,
    PulseLoader,
    ErrorPage,
  },

  props: {
    code: {
      type: String,
      default: '',
    },

    slug: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: true,
      donationPage: {
        contents: {},
      },
      showError: false,
    }
  },

  computed: {
    cardAlignment() {
      if (
        !this.donationPage.contents ||
        this.donationPage.contents.form_alignment === 'left'
      ) {
        return 'donate-page__start'
      }

      if (this.donationPage.contents.form_alignment === 'center') {
        return 'donate-page__center'
      }
      return 'donate-page__end'
    },
  },

  async created() {
    let result
    let code

    try {
      if (this.slug) {
        result = await PageService.getDonationPageBySlug(
          this.code.toUpperCase(),
          this.slug,
        )
      } else if (this.code && this.code.length === 2 && !this.slug) {
        result = await PageService.getDefaultPageByOrganization(
          this.code.toUpperCase(),
        )
      } else if (this.code && this.code.length > 2 && !this.slug) {
        result = await PageService.getDonationPageBySlug(
          process.env.VUE_APP_ORGANIZATION_COUNTRY_CODE,
          this.code,
        )
      } else {
        result = await PageService.getDefaultPage()
      }

      
    } catch (error) {
      this.loading = false

      if (error.status === 404) {
        this.showError = true;
      }
    }

    this.initializePage(result.data.data)

      code = this.code || this.donationPage.organization.country_code

      this.setCurrentCountryCode(code.toUpperCase())
      this.setCurrentCurrency(this.donationPage.organization.currency_code)

      initAnalytics({
        google: this.donationPage.google_analytics_id,
      })

      this.$gtm.trackEvent({
        category: 'ADRA',
        action: 'visit',
        label: 'DonationPageSlug',
        value: this.donationPage.slug,
      })

    
  },

  beforeDestroy() {
    let bodyElement = document.body
    bodyElement.style = ''
  },

  methods: {
    ...mapActions({
      setCurrentCountryCode: 'setCurrentCountryCode',
      setCurrentCurrency: 'setCurrentCurrency',
    }),
    initializePage(data) {
      this.donationPage = { ...data, ...data.organization }
      this.donationPage.contents = JSON.parse(data.contents)

      const { amount_values } = this.donationPage.contents
      this.donationPage.contents.amount_values =
        typeof amount_values === 'string'
          ? JSON.parse(amount_values)
          : amount_values

      this.loading = false
      let bodyElement = document.body

      if (this.donationPage.background_image) {
        const backgroundURL =  this.donationPage.background_image.replace(
            /^http:\/\//i,
            'https://',
        )
        bodyElement.style.background = `#322d2b url('${backgroundURL}') no-repeat center top`
        bodyElement.style.backgroundAttachment = 'fixed'
        bodyElement.style.backgroundSize = 'cover'
      }
    },
  },
}
</script>
