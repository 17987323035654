import types from './mutation-types'

export default {
  [types.updateSidebar](state, showSidebar) {
    state.showSidebar = showSidebar
  },

  [types.setReferrer](state, referrer) {
    state.referrer = referrer
  },

  [types.setCurrentCountryCode](state, countryCode) {
    state.currentCountryCode = countryCode
  },
  [types.setCurrentCurrency](state, currency) {
    state.currentCurrency = currency
  },
}
