<template>
  <div
    :class="{
      'amount-input__left': !currencyOnRight,
      'amount-input__right': currencyOnRight
    }"
    class="amount-input"
  >
    <!-- <span>{{ currencySymbol }}</span> -->
    <el-select
      v-model="currency"
      class="border-0"
    >
      <el-option
        v-for="(cur, index) in filteredCurrencies"
        :key="index"
        :value="cur.value"
        :label="cur.symbol"
      />
    </el-select>
    <input
      v-model="currentValue"
      class="w-full"
      type="number"
      placeholder="Enter other amount"
      step=".01"
      @input="onInput($event)"
    >
  </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex'
import currencies from '@/helpers/currencies'
import get from 'lodash/get'

export default {
  props: {
    currencyCode: {
      type: String,
      default: 'USD',
    },

    currencyOnRight: {
      type: Boolean,
      default: false,
    },

    currencyData: {
      type: Object,
      default:  () => ({}),
    },

    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentValue: '',
      currencies: Object.values(currencies),
    }
  },

  computed: {
    ...mapGetters(['currencySymbol']),

    currency: {
      set(value) {
        this.setCurrentCurrency(value)
      },
      get() {
        return this.currencySymbol
      },
    },

    filteredCurrencies() {
      let data = []
      Object.keys(this.currencyData).forEach(currency => {
        let cur = this.currencies.find(curr => curr.code === currency)
        if (cur) {
          data.push({
            value: currency,
            ...cur,
          })
        } else {
          data.push({
            value: currency,
            symbol: currency,
          })
        }
        
      })
      return data
    },
  },

  watch: {
    value() {
      this.currentValue = this.value
    },
  },

  mounted() {
    this.currentValue = this.value
  },

  methods: {
    ...mapActions({
      setCurrentCurrency: 'setCurrentCurrency',
    }),
    countDecimals(value) {
      if (Math.floor(value) === parseFloat(value) || value == '') {
        return 0
      }

      let split = value.toString().split('.')
      if (split.length < 2) {
        split = value.toString().split(',')
      }
      return split[1].length || 0
    },

    getFormattedValue(value) {
      if (Math.floor(value) === parseFloat(value) || value == '') {
        return value
      }

      let split = value.toString().split('.')
      if (split.length < 2) {
        split = value.toString().split(',')
      }
      return `${split[0]}.${split[1].substr(0, 2)}`
    },

    onInput(event) {
      console.log(this.countDecimals(event.target.value))
      if (this.countDecimals(event.target.value) < 3) {
        this.$emit('input', event.target.value)
      } else {
        this.currentValue = this.getFormattedValue(event.target.value)
        this.$emit('input', this.getFormattedValue(event.target.value))
      }
    },
  },
}
</script>
