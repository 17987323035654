<template>
  <div class="auth">
    <form
      class="bg-white shadow-md rounded px-8 pt-6 pb-8"
      @submit.prevent="submitForm"
    >
      <div class="mb-4">
        <h4>{{ isCreateNewForm ? "Create New Card" : "Update Card" }}</h4>
      </div>
      <div v-if="isCreateNewForm" class="mb-4">
        <card
          :options="stripeOptions"
          :stripe="token"
          class="donate-card__input_full"
          @change="handleCardChange"
        />

        <p v-if="serverValidationError" class="invalid-feedback mt-0">
          {{ serverValidationError }}
        </p>
      </div>
      <div>
        <section class="donate-card__info_section">
          <h4>Billing Address</h4>
          <input
            v-model="form.address_line1"
            class="donate-card__input_full"
            name="address_line1"
            placeholder="Street Address *"
          />
          <DonateCardStepTwoSelectCountry
            :countries="countries"
            v-model="form.address_country"
            name="country"
            class="md:w-1/2 my-3"
            @input="handleCountryChange"
          />
          <DonateCardStepTwoSelectState
            v-if="form.address_country === 'US'"
            v-model="form.address_state"
          />
          <input
            v-model="form.address_city"
            class="donate-card__input_half donate-card__input_half_l"
            name="city"
            placeholder="City *"
          />
          <input
            v-model="form.address_zip"
            class="donate-card__input_half donate-card__input_half_r"
            name="zip_code"
            placeholder="Zip Code *"
          />
        </section>

        <section v-if="!isCreateNewForm" class="donate-card__info_section">
          <h4>Card Information</h4>
          <DonateCardStepTwoSelectMonth
            v-model="form.exp_month"
            :disabled="!cardEditable"
          />
          <DonateCardStepTwoSelectYear
            v-model="form.exp_year"
            :disabled="!cardEditable"
          />

          <input
            v-model="form.name"
            class="donate-card__input_full"
            name="name"
            placeholder="Name *"
          />
        </section>

        <section class="donate-card__footer_section">
          <input
            v-model="form.is_default"
            class="donate-card__input_checkbox"
            type="checkbox"
            name="is-default"
          />
          <span class="donate-card__input_checkbox"
            >Set this card as default</span
          >
        </section>

        <p v-if="serverValidationError" class="invalid-feedback">
          {{ serverValidationError }}
        </p>
      </div>
      <div class="flex items-center justify-between">
        <button
          v-loading="isLoading"
          class="button button-green button-sm"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import StripeService from "@/api/StripeService";
import { Card, createToken } from "vue-stripe-elements-plus";
import _get from "lodash.get";
import DonateCardStepTwoSelectCountry from "@/components/DonatePage/DonateCardStepTwoSelectCountry";
import DonateCardStepTwoSelectState from "@/components/DonatePage/DonateCardStepTwoSelectState";
import DonateCardStepTwoSelectMonth from "@/components/DonatePage/DonateCardStepTwoSelectMonth";
import DonateCardStepTwoSelectYear from "@/components/DonatePage/DonateCardStepTwoSelectYear";

export default {
  name: "CardForm",

  components: {
    Card,
    DonateCardStepTwoSelectCountry,
    DonateCardStepTwoSelectState,
    DonateCardStepTwoSelectMonth,
    DonateCardStepTwoSelectYear
  },

  props: {
    card: {
      type: Object,
      default() {
        return null;
      }
    },
    token: {
      type: String,
      required: false,
      default: ""
    },
    fullName: {
      type: String,
      required: true
    },
    countries: {
      type: [Array, Object],
      required: false,
      default() {
        return [];
      }
    },
    cardEditable: {
      type: Boolean,
      required: false,
      default: true
    },
    organization: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      form: {
        organization_id: process.env.VUE_APP_ORGANIZATION_ID,
        address_line1: _get(this.card, "address_line1"),
        address_city: _get(this.card, "address_city"),
        address_state: _get(this.card, "address_state"),
        address_zip: _get(this.card, "address_zip"),
        address_country: _get(this.card, "address_country") || "",
        exp_month: _get(this.card, "exp_month", "").toString() || "",
        exp_year: _get(this.card, "exp_year") || "",
        name: _get(this.card, "name") || "",
        is_default: _get(this.card, "is_default")
      },
      cardId: null,
      stripeOptions: {
        hidePostalCode: true
      },
      serverValidationError: null,
      isLoading: false
    };
  },

  computed: {
    isCreateNewForm() {
      return !this.card;
    }
  },

  methods: {
    async submitForm() {
      this.isLoading = true;
      try {
        if (this.isCreateNewForm) {
          const createTokenResult = await createToken({
            name: this.fullName,
            address_line1: this.form.address_line1,
            address_city: this.form.address_city,
            address_state: this.form.address_state,
            address_zip: this.form.address_zip,
            address_country: this.form.address_country,
            currency: "usd"
          });

          this.cardId = _get(createTokenResult, "token.id");
          const createCardResult = await StripeService.createCard({
            ...this.form,
            organization_id:
              this.organization.id || process.env.VUE_APP_ORGANIZATION_ID,
            token: this.cardId
          });

          if (_get(createCardResult, "data.success") === true) {
            this.$parent.$emit("success");
          } else {
            this.serverValidationError =
              "There was an error while adding the card.";
          }
        } else {
          const updateCardResult = await StripeService.updateCard(
            this.card.id,
            {
              ...this.form,
              organization_id:
                this.organization.id || process.env.VUE_APP_ORGANIZATION_ID
            }
          );
          if (_get(updateCardResult, "data.success") === true) {
            this.$parent.$emit("success");
          } else {
            this.serverValidationError =
              "There was an error while updating the card.";
          }
        }
      } catch (ex) {
        this.serverValidationError = this.isCreateNewForm
          ? "There was an error while adding the card."
          : "There was an error while updating the card.";
      }
      this.isLoading = false;
    },
    handleCardChange(event) {
      this.isCardValid = event.complete && !event.error;
    },
    handleCountryChange(countryCode) {
      if (countryCode === "US") return;
      this.form.state = "";
    }
  }
};
</script>
