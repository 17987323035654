<template>
  <div class="auth">
    <form class="auth__form" @submit.prevent="submitLogin">
      <div class="auth__form__section auth__header">
        <h1 class="auth__title">Welcome Back!</h1>
        <h4 class="auth__subtitle">Login below to get started!</h4>
      </div>
      <hr class="auth__hr" />
      <div class="auth__form__section">
        <R64Input
          id="identifier"
          v-model="loginForm.identifier"
          :state="inputState($v.loginForm.identifier)"
          placeholder="Email address"
          invalid-message="Email is required"
          @blur="$v.loginForm.identifier.$touch()"
        />
        <R64Input
          id="password"
          v-model="loginForm.password"
          :state="inputState($v.loginForm.password)"
          placeholder="Password"
          type="password"
          invalid-message="Password is required"
          @blur="$v.loginForm.password.$touch()"
        />

        <p v-if="serverValidationError" class="invalid-feedback">
          {{ serverValidationError }}
        </p>

        <div class="auth__buttons">
          <button
            class="auth__buttons__link"
            type="button"
            @click.self="forgotPassword"
          >
            Forgot Password?
          </button>
          <button
            v-loading="isLoading"
            class="button button-green"
            type="submit"
          >
            Login
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import R64Input from "@/components/r64/R64Input";
import { required } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("auth");

export default {
  name: "UserLogin",

  components: {
    R64Input
  },

  data() {
    return {
      loginForm: {
        identifier: "",
        password: ""
      },
      serverValidationError: null,
      isLoading: false
    };
  },

  mounted() {
    this.reset();
  },

  methods: {
    ...mapActions(["login", "reset"]),

    submitLogin() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isLoading = true;
      this.login(this.loginForm)
        .then(() => {
          /*
          this.$router.push({
            name: 'MyCourses'
          })
          */
          this.$parent.$emit("close");
          this.isLoading = false;
        })
        .catch(errorResult => {
          this.serverValidationError = errorResult.error;
          this.isLoading = false;
        });
    },

    forgotPassword() {
      this.$parent.$emit("open-forgot-password");
    }
  },

  validations: {
    loginForm: {
      identifier: {
        required
      },

      password: {
        required
      }
    }
  }
};
</script>
