<template>
  <div class="auth">
    <form class="auth__form" @submit.prevent="submitReset">
      <div class="auth__form__section auth__header">
        <!-- <RocketIcon class="auth__icon"/> -->
        <h1 class="auth__title">Reset password</h1>
      </div>
      <hr class="auth__hr my-4" />
      <div v-if="!submitted" class="auth__form__section">
        <R64Input
          id="identifier"
          v-model="resetForm.password"
          :state="inputState($v.resetForm.password)"
          type="password"
          placeholder="New password"
          invalid-message="Password is required"
          @blur="$v.resetForm.password.$touch()"
        />

        <R64Input
          id="identifier"
          v-model="resetForm.password_confirmation"
          :state="inputState($v.resetForm.password_confirmation)"
          type="password"
          placeholder="Confirm new password"
          invalid-message="Both password should be the same"
          @blur="$v.resetForm.password_confirmation.$touch()"
        />

        <p v-if="serverValidationError" class="invalid-feedback">
          {{ serverValidationError }}
        </p>

        <div class="auth__buttons">
          <router-link :to="{ name: 'UserLogin' }" class="auth__buttons__link"
            >Remember it now?</router-link
          >
          <button
            v-loading="isLoading"
            class="button button-green"
            type="submit"
          >
            Reset
          </button>
        </div>
      </div>
      <div v-else class="auth__form__section">
        <p>Your password has successfully been changed!</p>
      </div>
    </form>
  </div>
</template>

<script>
import AuthAPI from "@/api/auth";
import R64Input from "@/components/r64/R64Input";
import { required, sameAs } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("auth");

export default {
  name: "UserReset",

  components: {
    R64Input
  },

  data() {
    return {
      resetForm: {
        password: "",
        password_confirmation: ""
      },
      serverValidationError: null,
      submitted: false,
      isLoading: false
    };
  },

  mounted() {
    this.reset();
  },

  methods: {
    ...mapActions(["reset"]),
    submitReset() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      var str = this.$route.params.token;
      var email = str.trim().match("email=(.*)&token")[1];
      var token = str.trim().split("&token=")[1];
      this.isLoading = true;
      AuthAPI.resetPassword({
        email: email,
        password: this.resetForm.password,
        password_confirmation: this.resetForm.password_confirmation,
        token: token
      })
        .then(() => {
          this.serverValidationError = null;
          this.submitted = true;
          this.isLoading = false;
        })
        .catch(({ data: errorResult }) => {
          this.submitted = false;
          this.serverValidationError = errorResult.error;
          this.isLoading = false;
        });
    }
  },

  validations: {
    resetForm: {
      password: {
        required
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  }
};
</script>
