import http from "@/http";

export default {
  donate: data => http.post("/api/donations", data),
  getDonations: params =>
    http.get("/api/donations", {
      shouldAuthenticate: true,
      params
    })
};
