<template>
  <select
    class="donate-card__input_half donate-card__input_half_r"
    required
    @change="$emit('input', $event.target.value)"
  >
    <option value="" disabled selected>State/Prov. *</option>
    <option :selected="value === 'None'" value="None">None</option>
    <option
      v-for="(state, index) in states"
      :key="index"
      :value="state.value"
      :selected="state.value === value"
      >{{ state.text }}</option
    >
  </select>
</template>
<script>
export default {
  name: "DonateCardStepTwoSelectState",

  props: {
    value: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      states: [
        { value: "AL", text: "AL - Alabama" },
        { value: "AR", text: "AR - Arkansas" },
        { value: "AZ", text: "AZ - Arizona" },
        { value: "CA", text: "CA - California" },
        { value: "CO", text: "CO - Colorado" },
        { value: "CT", text: "CT - Connecticut" },
        { value: "DC", text: "DC - District of Columbia" },
        { value: "DE", text: "DE - Delaware" },
        { value: "FL", text: "FL - Florida" },
        { value: "GA", text: "GA - Georgia" },
        { value: "HI", text: "HI - Hawaii" },
        { value: "IA", text: "IA - Iowa" },
        { value: "ID", text: "ID - Idaho" },
        { value: "IL", text: "IL - Illinois" },
        { value: "IN", text: "IN - Indiana" },
        { value: "KS", text: "KS - Kansas" },
        { value: "KY", text: "KY - Kentucky" },
        { value: "LA", text: "LA - Louisiana" },
        { value: "MA", text: "MA - Massachusetts" },
        { value: "MD", text: "MD - Maryland" },
        { value: "ME", text: "ME - Maine" },
        { value: "MI", text: "MI - Michigan" },
        { value: "MN", text: "MN - Minnesota" },
        { value: "MO", text: "MO - Missouri" },
        { value: "MS", text: "MS - Mississippi" },
        { value: "MT", text: "MT - Montana" },
        { value: "NC", text: "NC - North Carolina" },
        { value: "ND", text: "ND - North Dakota" },
        { value: "NE", text: "NE - Nebraska" },
        { value: "NH", text: "NH - New Hampshire" },
        { value: "NJ", text: "NJ - New Jersey" },
        { value: "NM", text: "NM - New Mexico" },
        { value: "NV", text: "NV - Nevada" },
        { value: "NY", text: "NY - New York" },
        { value: "OH", text: "OH - Ohio" },
        { value: "OK", text: "OK - Oklahoma" },
        { value: "OR", text: "OR - Oregon" },
        { value: "PA", text: "PA - Pennsylvania" },
        { value: "RI", text: "RI - Rhode Island" },
        { value: "SC", text: "SC - South Carolina" },
        { value: "SD", text: "SD - South Dakota" },
        { value: "TN", text: "TN - Tennessee" },
        { value: "TX", text: "TX - Texas" },
        { value: "UT", text: "UT - Utah" },
        { value: "VA", text: "VA - Virginia" },
        { value: "VT", text: "VT - Vermont" },
        { value: "WA", text: "WA - Washington" },
        { value: "WI", text: "WI - Wisconsin" },
        { value: "WV", text: "WV - West Virginia" },
        { value: "WY", text: "WY - Wyoming" },
        { value: "AS", text: "AS - American Samoa" },
        { value: "FM", text: "FM - Federated States of Micronesia" },
        { value: "GU", text: "GU - Guam" },
        { value: "MH", text: "MH - Marshall Islands" },
        { value: "MP", text: "MP - Northern Mariana Islands" },
        { value: "PR", text: "PR - Puerto Rico" },
        { value: "PW", text: "PW - Palau" },
        { value: "VI", text: "VI - Virgin Islands" },
        { value: "AA", text: "AA - Armed Forces Americas" },
        { value: "AE", text: "AE - Armed Forces" },
        { value: "AP", text: "AP - Armed Forces Pacific" },
        { value: "AB", text: "AB - Alberta" },
        { value: "BC", text: "BC - British Columbia" },
        { value: "MB", text: "MB - Manitoba" },
        { value: "NB", text: "NB - New Brunswick" },
        { value: "NL", text: "NL - Newfoundland and Labrador" },
        { value: "NS", text: "NS - Nova Scotia" },
        { value: "NT", text: "NT - Northwest Territories" },
        { value: "NU", text: "NU - Nunavut" },
        { value: "ON", text: "ON - Ontario" },
        { value: "PE", text: "PE - Prince Edward Island" },
        { value: "QC", text: "QC - Quebec" },
        { value: "SK", text: "SK - Saskatchewan" },
        { value: "YT", text: "YT - Yukon" }
      ]
    };
  }
};
</script>
