<template>
  <iframe
    :id="`form-${formId}`"
    :src="url"
    title="Monthly Donation Form"
    scrolling="no"
    class="blackbaud-donation-form bg-transparent max-w-md min-w-xs, w-full h-full border-none overflow-hidden transition-all duration-1000 min-h-0"
  >
  </iframe>
</template>

<script>
export default {
  name: "DonateCardBlackbaudIframe",
  props: {
    iframeUrl: {
      type: String
    }
  },
  data() {
    return {
      url: this.iframeUrl
    };
  },
  computed: {
    formId() {
      const url = new URL(
        "https://host.nxt.blackbaud.com/donor-form?svcid=renxt&formId=12af5db6-0cb7-444f-9682-565ee95cb81f&envid=p-0DiADtygIkqHwdRwvcCtEA"
      );
      return url.searchParams.get("formId");
    }
  },
  mounted() {
    const bbemlParser = new RegExp("[?&]bbeml=([^&#]*)").exec(
      document.location.search
    );
    const bbeml = bbemlParser ? decodeURI(bbemlParser[1]) || 0 : "";
    this.url = this.url + "&referral=" + document.referrer + "&bbeml=" + bbeml;
    window.addEventListener("message", event => {
      if (event.data === "checkout loaded") {
        this.$el.scrollIntoView();
      }
      if (event.data.messageType === "height-change") {
        this.$el.style.minHeight = event.data.message.height;
      }
    });
  }
};
</script>
