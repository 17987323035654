<template>
  <div class="">
    <p class="text-xl font-semibold text-left">Remaining</p>
    <AnimatedCountdown :time="countdown">
      <template slot-scope="{ remaining }">
        <div class="flex flex-wrap">
          <div
            class="flex flex-col items-center justify-start w-1/2 sm:w-auto sm:flex-row"
          >
            <span class="text-2xl font-semibold ">{{ remaining.days }}</span>
            <span class="ml-1 text-lg font-thin uppercase ">{{
              "day" | plural(remaining.days)
            }}</span>
          </div>
          <div
            class="flex flex-col items-center justify-start w-1/2 sm:w-auto sm:flex-row sm:ml-3"
          >
            <span class="text-2xl font-semibold ">{{ remaining.hours }}</span>
            <span class="ml-1 text-lg font-thin uppercase ">{{
              "hour" | plural(remaining.hours)
            }}</span>
          </div>
          <div
            class="flex flex-col items-center justify-start w-1/2 sm:w-auto sm:flex-row sm:ml-3"
          >
            <span class="text-2xl font-semibold ">{{ remaining.minutes }}</span>
            <span class="ml-1 text-lg font-thin uppercase ">{{
              "minute" | plural(remaining.minutes)
            }}</span>
          </div>
          <div
            class="flex flex-col items-center justify-start w-1/2 sm:w-auto sm:flex-row sm:ml-3"
          >
            <span class="text-2xl font-semibold "
              >{{ remaining.seconds }}
            </span>
            <span class="ml-1 text-lg font-thin uppercase ">{{
              "second" | plural(remaining.seconds)
            }}</span>
          </div>
        </div>
      </template>
    </AnimatedCountdown>
  </div>
</template>

<script>
import { AnimatedCountdown } from "@adra-network/vue-animatron";

export default {
  name: "DonateCardCountdown",
  props: {
    countdown: {
      type: Number,
      required: true
    }
  },
  components: {
    AnimatedCountdown
  },
  filters: {
    plural(value, count) {
      if (!value) return "";
      if (count > 1) {
        return `${value}s`;
      }
      return value;
    }
  }
};
</script>

<style lang="scss" scoped></style>
