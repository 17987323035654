import Vue from "vue";
import VueGtm from "vue-gtm";
import router from "./router";

export const initAnalytics = ({ google: trackingId }) => {
  Vue.use(VueGtm, {
    id: trackingId,
    debug: true,
    vueRouter: router
  });
};
