<template>
  <div class="account-settings">
    <!-- Breadcrumb -->
    <div class="breadcrumb">
      <ul class="breadcrumb__wrapper">
        <li class="breadcrumb__item">
          <router-link :to="{ name: 'DonatePage' }">Home</router-link>
          <CheveronRight class="mx-2" width="20px" height="20px" />
        </li>
        <li class="breadcrumb__item">
          <span class="breadcrumb__item__link">Account Settings</span>
        </li>
      </ul>
    </div>
    <!-- End of Breadcrumb -->

    <div class="md:grid grid-cols-4 gap-10">
      <div>
        <div class="hidden md:block bg-white py-4 rounded-lg shadow-md">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{
              'border-button-green text-button-green font-semibold':
                activeTab === tab.value
            }"
            class="h-8 flex items-center px-4 my-2 border-l-4 border-transparent cursor-pointer"
            @click="activeTab = tab.value"
          >
            <p>{{ tab.label }}</p>
          </div>
        </div>

        <select
          v-model="activeTab"
          class="donate-card__input_half donate-card__input_half_r block md:hidden"
        >
          <option v-for="tab in tabs" :key="tab.value" :value="tab.value">{{
            tab.label
          }}</option>
        </select>
      </div>
      <div class="col-span-3">
        <div v-if="activeTab === 'profile'" class="account-settings__card mb-6">
          <div class="account-settings__card__header">
            <h5>Profile</h5>
          </div>

          <div class="account-settings__card__table__wrapper">
            <user-form />
          </div>
        </div>

        <div
          v-if="activeTab === 'reset_pin'"
          class="account-settings__card mb-6"
        >
          <div class="account-settings__card__header">
            <h5>Pin</h5>
          </div>

          <div class="account-settings__card__table__wrapper">
            <update-pin-form />
          </div>
        </div>

        <div
          v-if="activeTab === 'reset_password'"
          class="account-settings__card mb-6"
        >
          <div class="account-settings__card__header">
            <h5>Reset Password</h5>
          </div>

          <div class="account-settings__card__table__wrapper">
            <reset-password />
          </div>
        </div>

        <div
          v-if="activeTab === 'card_and_subscription'"
          class="account-settings__card mb-6"
        >
          <div class="account-settings__card__header">
            <h5>Card and Subscription Management</h5>
          </div>

          <base-tabs>
            <base-tab
              :selected="currentTab === 'Card'"
              name="Card Management"
              @click="setCurrentTab('Card')"
            >
              <div class="account-settings__card__table__wrapper">
                <div class="account-settings__card__table__header mt-4">
                  <button
                    class="button button-sm button-green"
                    @click="addCard"
                  >
                    Add Card
                  </button>
                </div>
                <table v-if="!isLoading && hasCards" class="adra-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Exp Month</th>
                      <th>Exp Year</th>
                      <th>Last 4</th>
                      <th>Default Card</th>
                      <th class="min-w-180px">Operations</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="card in cards" :key="card.id">
                      <td>{{ card.brand }}</td>
                      <td>{{ card.exp_month }}</td>
                      <td>{{ card.exp_year }}</td>
                      <td>{{ card.last4 }}</td>
                      <td>{{ card.is_default ? "Yes" : "No" }}</td>
                      <td class="min-w-180px">
                        <div>
                          <button
                            class="button button-sm button-orange"
                            @click="editCard(card)"
                          >
                            Edit
                          </button>
                          <button
                            class="button button-sm button-red ml-2"
                            @click="deleteCard(card)"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  v-else-if="!isLoading && !hasCards"
                  class="text-lg text-center"
                >
                  No cards found
                </p>
                <TableLoader v-show="isLoading" />
              </div>
            </base-tab>
            <base-tab
              :selected="currentTab === 'Subscription'"
              name="Subscription Management"
              @click.native="setCurrentTab('Subscription')"
            >
              <div class="account-settings__card__table__wrapper">
                <table v-if="!isLoading && hasSubscriptions" class="adra-table">
                  <thead>
                    <tr>
                      <th>Created</th>
                      <th>Amount</th>
                      <th>Billing</th>
                      <!-- <th>Days Until Due Date</th> -->
                      <th>Ended</th>
                      <th>Cancelled</th>
                      <th class="min-w-180px">Operations</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="sub in subscriptions" :key="sub.id">
                      <td>{{ getUserTimestamp(sub.created) || "-" }}</td>
                      <td>{{ sub.amount }}</td>
                      <td>
                        {{
                          `Subscribed to - ${sub.metadata.donation_page_name}`
                        }}
                      </td>
                      <!-- <td>{{ sub.days_until_due }}</td> -->
                      <td>{{ getUserTimestamp(sub.ended_at) || "-" }}</td>
                      <td>{{ getUserTimestamp(sub.canceled_at) || "-" }}</td>
                      <td class="min-w-180px">
                        <div>
                          <button
                            class="button button-sm button-orange"
                            @click="editSubscription(sub)"
                          >
                            Edit
                          </button>
                          <button
                            class="button button-sm button-red ml-2"
                            @click="deleteSubscription(sub)"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  v-else-if="!isLoading && !hasSubscriptions"
                  class="text-lg text-center"
                >
                  No subscriptions found
                </p>
                <TableLoader v-show="isLoading" />
              </div>
            </base-tab>
          </base-tabs>
        </div>

        <div
          v-if="activeTab === 'donation_history'"
          class="account-settings__card"
        >
          <div class="account-settings__card__header">
            <h5>Donation History</h5>
          </div>

          <div class="account-settings__card__table__wrapper">
            <date-picker
              v-model="donationHistoryDateRange"
              class="ml-4"
              value-type="YYYY-MM-DD HH:mm"
              range
              @change="handleDonationHistoryDateRangeChange"
            />
            <div v-show="!isLoadingDonations && hasDonations">
              <table class="adra-table">
                <thead>
                  <tr>
                    <th>Appeal Name</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Card Type</th>
                    <th>Card Last 4</th>
                    <th>Status</th>
                    <th class="min-w-180px">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="donation in donations" :key="donation.id">
                    <td>{{ _get(donation, "appeal.name") }}</td>
                    <td>
                      <el-tooltip placement="top">
                        <div slot="content">
                          Refunded amount: {{ _get(donation, "refunded_amount")
                          }}<br />Chargeback amount:
                          {{ _get(donation, "chargeback_amount") }}
                        </div>
                        <p>{{ _get(donation, "amount") }}</p>
                      </el-tooltip>
                    </td>
                    <td>{{ _get(donation, "currency") }}</td>
                    <td>{{ _get(donation, "card_type") }}</td>
                    <td>{{ _get(donation, "card_last4") }}</td>
                    <td class="capitalize">
                      <el-tooltip placement="top">
                        <div slot="content">
                          Failure reason:
                          {{ _get(donation, "failure_reason") || "none" }}
                        </div>
                        <p>{{ _get(donation, "status") }}</p>
                      </el-tooltip>
                    </td>
                    <td>{{ _get(donation, "date") }}</td>
                  </tr>
                </tbody>
              </table>
              <v-page
                :total-row="donationMeta.total"
                align="center"
                language="en"
                @page-change="handleDonationPageChanged"
              />
            </div>
            <p
              v-show="!isLoadingDonations && !hasDonations"
              class="text-lg text-center"
            >
              No donations found
            </p>
            <TableLoader v-show="isLoadingDonations" />
          </div>
        </div>
      </div>
    </div>

    <BaseModal
      v-if="showNewCardForm"
      @close="handleCloseCardForm"
      @success="handleCardUpdateSuccess"
    >
      <CardForm
        slot="body"
        :token="pageData.organization && pageData.organization.stripe_pk"
        :full-name="fullName"
        :countries="pageData.countries"
        :organization="pageData.organization"
      />
    </BaseModal>

    <BaseModal
      v-if="showEditCardForm"
      @close="handleCloseCardForm"
      @success="handleCardUpdateSuccess"
    >
      <CardForm
        slot="body"
        :card="currentCard"
        :full-name="fullName"
        :countries="pageData.countries"
        :card-editable="isCardEditable"
        :organization="pageData.organization"
      />
    </BaseModal>

    <BaseModal
      v-if="showEditSubForm"
      @close="showEditSubForm = false"
      @submit="handleSaveSubscription"
      @success="handleSubscriptionUpdateSuccess"
    >
      <SubscriptionForm
        slot="body"
        :subscription="currentSub"
        :cards="cards"
        :errors="errors"
        :is-loading="isSubscriptionFormLoading"
      />
    </BaseModal>

    <BaseConfirmationBox
      v-if="showConfirmationBox"
      message="Are you sure you want to delete this card?"
      @close="showConfirmationBox = false"
      @ok="confirmDeleteCard"
    />

    <BaseConfirmationBox
      v-if="showConfirmationSubBox"
      message="Are you sure you want to delete this subscription?"
      @close="showConfirmationSubBox = false"
      @ok="confirmDeleteSub"
    />
  </div>
</template>

<script>
// import { initAnalytics } from '../social'
import StripeService from "../api/StripeService";
import PageService from "../api/PageService";
import DonationService from "../api/DonationService";
import CheveronRight from "@/assets/cheveron-right.svg";
import CardForm from "@/components/AccountSettings/CardForm";
import SubscriptionForm from "@/components/AccountSettings/SubscriptionForm";
import TableLoader from "@/components/AccountSettings/TableLoader";
import UserForm from "@/components/AccountSettings/UserForm";
import UpdatePinForm from "@/components/AccountSettings/UpdatePinForm";
import ResetPassword from "@/components/AccountSettings/ResetPassword";
import { mapGetters } from "vuex";
import _get from "lodash.get";
import { utcToZonedTime, format } from "date-fns-tz";
import { Page } from "v-page";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "AccountSettings",

  components: {
    CheveronRight,
    CardForm,
    SubscriptionForm,
    TableLoader,
    "v-page": Page,
    DatePicker,
    UserForm,
    UpdatePinForm,
    ResetPassword
  },

  props: {},

  data() {
    return {
      showNewCardForm: false,
      showEditCardForm: false,
      showEditSubForm: false,
      cards: [],
      subscriptions: [],
      donations: [],
      isLoading: false,
      isLoadingDonations: false,
      currentCard: null,
      currentSub: null,
      showConfirmationBox: false,
      showConfirmationSubBox: false,
      pageData: null,
      errors: null,
      isSubscriptionFormLoading: false,
      donationMeta: {
        current_page: null,
        from: null,
        last_page: null,
        per_page: null,
        to: null,
        total: null
      },
      paginationSettings: {
        language: "en"
      },
      currentTab: "Card",
      donationHistoryDateRange: null,
      activeTab: "donation_history",
      tabs: [
        { label: "Donation History", value: "donation_history" },
        {
          label: "Card and Subscription Management",
          value: "card_and_subscription"
        },
        { label: "Profile", value: "profile" },
        { label: "Reset PIN", value: "reset_pin" },
        { label: "Reset Password", value: "reset_password" }
      ],
      isCardEditable: false
    };
  },

  computed: {
    ...mapGetters("auth", ["userToken", "fullName"]),

    hasCards() {
      return this.cards.length > 0;
    },

    hasSubscriptions() {
      return this.subscriptions.length > 0;
    },
    hasDonations() {
      return this.donations.length > 0;
    }
  },

  async created() {
    let cards = [];
    let subscriptions = [];
    let pageData;
    let donations = [];
    let userToken = this.userToken;
    if (_get(this.$route, "query.state")) {
      this.activeTab = "card_and_subscription";
      userToken = _get(this.$route, "query.token");
    }
    try {
      this.isLoading = true;
      this.isLoadingDonations = true;
      [cards, subscriptions, pageData, donations] = await Promise.all([
        StripeService.getDonorCards(userToken).catch(x => console.log(x)),
        StripeService.getDonorSubscriptions(userToken).catch(x =>
          console.log(x)
        ),
        PageService.getDefaultPage().catch(x => console.log(x)),
        this.getDonations({ page: 1 }).catch(x => console.log(x))
      ]);
    } catch (ex) {
      console.log(ex);
    }
    this.isLoading = false;
    this.isLoadingDonations = false;

    this.initializePage(
      _get(cards, "data.data", []),
      _get(subscriptions, "data.data", []),
      _get(pageData, "data.data"),
      donations
    );

    if (_get(this.$route, "query.state")) {
      let cardId = _get(this.$route, "query.card");
      let card = this.cards.find(card => card.id === cardId);
      this.isCardEditable = true;
      this.editCard(card);
    }
  },

  methods: {
    _get,

    initializePage(cards, subscriptions, pageData, donations) {
      this.cards = cards;
      this.subscriptions = subscriptions;
      this.pageData = pageData;
      this.donations = donations;
    },

    addCard() {
      this.showNewCardForm = true;
    },

    editCard(card) {
      this.currentCard = card;
      this.showEditCardForm = true;
    },

    deleteCard(card) {
      this.currentCard = card;
      this.showConfirmationBox = true;
    },

    async confirmDeleteCard() {
      try {
        await StripeService.deleteCard(this.currentCard.id);

        const newCards = await StripeService.getDonorCards(this.userToken);

        this.cards = _get(newCards, "data.data");
      } catch (ex) {
        this.$toasted.error("There was an error while deleting the card.", {
          duration: 4000
        });
      }
      this.showConfirmationBox = false;
    },

    async handleCardUpdateSuccess() {
      this.showNewCardForm = false;
      this.showEditCardForm = false;
      this.isCardEditable = false;

      const newCards = await StripeService.getDonorCards(this.userToken);

      this.cards = _get(newCards, "data.data");
    },

    editSubscription(sub) {
      this.currentSub = sub;
      this.showEditSubForm = true;
    },

    deleteSubscription(sub) {
      this.currentSub = sub;
      this.showConfirmationSubBox = true;
    },

    async confirmDeleteSub() {
      try {
        await StripeService.deleteSubscription(this.currentSub.id);
        const newSubscriptions = await StripeService.getDonorSubscriptions(
          this.userToken
        );

        this.subscriptions = _get(newSubscriptions, "data.data");
      } catch (ex) {
        this.$toasted.error(
          "There was an error while deleting the subscription.",
          {
            duration: 4000
          }
        );
      }
      this.showConfirmationSubBox = false;
    },

    async handleSubscriptionUpdateSuccess() {
      this.showEditSubForm = false;

      const newSubscriptions = await StripeService.getDonorSubscriptions(
        this.userToken
      );

      this.subscriptions = _get(newSubscriptions, "data.data");
    },

    async handleSaveSubscription(subscription) {
      this.isSubscriptionFormLoading = true;
      try {
        const updateCardResult = await StripeService.updateSubscription(
          this.currentSub.id,
          subscription
        );
        if (_get(updateCardResult, "data.success") === true) {
          await this.handleSubscriptionUpdateSuccess();
        } else {
          this.errors = "There was an error while updating the card.";
        }
      } catch (ex) {
        this.errors = "There was an error while updating the card.";
      }
      this.isSubscriptionFormLoading = false;
    },

    handleCloseCardForm() {
      this.showEditCardForm = false;
      this.showNewCardForm = false;
      this.currentCard = null;
    },

    getUserTimestamp(date) {
      if (!date) {
        return "-";
      }
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return format(utcToZonedTime(date, timezone), "MMM d, yyyy HH:mm (O)");
    },

    async getDonations({ page, items_per_page = 10 }) {
      const response = await DonationService.getDonations({
        paginate: true,
        page: page,
        items_per_page,
        order_field: "created_at",
        order_direction: "desc",
        date_from:
          this.donationHistoryDateRange && this.donationHistoryDateRange[0],
        date_to:
          this.donationHistoryDateRange && this.donationHistoryDateRange[1]
      });
      const donations = _get(response, "data.data", []);
      this.donationMeta = _get(response, "data.meta");
      return new Promise(resolve => resolve(donations));
    },

    async handleDonationPageChanged(e) {
      this.isLoadingDonations = true;
      try {
        this.donations = await this.getDonations({
          page: e.pageNumber,
          items_per_page: e.pageSize
        });
      } catch {
        this.donations = [];
      }
      this.isLoadingDonations = false;
    },

    setCurrentTab(tab) {
      this.currentTab = tab;
    },

    async handleDonationHistoryDateRangeChange() {
      this.isLoadingDonations = true;
      try {
        this.donations = await this.getDonations({
          page: 1,
          items_per_page: 10
        });
      } catch {
        this.donations = [];
      }
      this.isLoadingDonations = false;
    }
  }
};
</script>

<style scoped>
.adra-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
