import AuthAPI from "@/api/auth";
import types from "./mutation-types";

export default {
  reset({ commit }) {
    commit(types.updateToken, null);
    commit(types.updateUser, null);
    commit(types.updateLoggedIn, false);
  },

  login({ commit }, loginData) {
    return new Promise((resolve, reject) => {
      AuthAPI.login(loginData)
        .then(({ data: result }) => {
          if (result.success) {
            commit(types.updateToken, result.data.token);
            commit(types.updateUser, result.data.user);
            commit(types.updateLoggedIn, true);
            resolve(result);
          } else {
            reject(result);
          }
        })
        .catch(({ data: errorResult }) => {
          reject(errorResult);
        });
    });
  },

  refresh({ commit }) {
    return new Promise((resolve, reject) => {
      AuthAPI.refresh()
        .then(({ data: result }) => {
          if (result.success && result.data.token) {
            commit(types.updateToken, result.data.token);
            resolve(result);
          } else {
            reject(result);
          }
        })
        .catch(({ data: errorResult }) => {
          reject(errorResult);
        });
    });
  },

  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      AuthAPI.user()
        .then(({ data: result }) => {
          if (result.success) {
            commit(types.updateUser, result.data);
            resolve(result);
          } else {
            reject(result);
          }
        })
        .catch(({ data: errorResult }) => {
          reject(errorResult);
        });
    });
  },

  setUser({ commit }, { user, token }) {
    commit(types.updateToken, token);
    commit(types.updateUser, user);
    commit(types.updateLoggedIn, true);
  },

  setToken({ commit, dispatch }, token) {
    commit(types.updateToken, token);
    commit(types.updateLoggedIn, true);
    dispatch("getUser");
  }
};
