<template>
  <div>
    <div class="sm:hidden">
      <select aria-label="Selected tab" class="form-select block w-full">
        <option
          v-for="(tab, index) in tabs"
          :key="index"
          :selected="tab.isActive"
          >{{ tab.name }}</option
        >
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="flex -mb-px">
          <a
            v-for="(tab, index) in tabs"
            :key="index"
            :class="[
              tab.isActive
                ? 'text-brand-green focus:outline-none focus:text-brand-green focus:border--brand-green'
                : 'text-gray hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300'
            ]"
            class="cursor-pointer ml-8 group inline-flex items-center py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5"
            @click="selectTab(tab)"
          >
            <span>{{ tab.name }}</span>
          </a>
        </nav>
      </div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTabs",

  data() {
    return {
      tabs: []
    };
  },

  computed: {
    isActive() {
      return this.tabs[0].isActive;
    }
  },

  created() {
    this.tabs = this.$children;
  },

  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.name === selectedTab.name;
      });
    }
  }
};
</script>
