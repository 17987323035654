<template>
  <div>
    <!-- <router-view /> -->

    <template v-if="isIframed">
      <router-view />
    </template>
    <template v-else>
      <div id="app" :class="{ 'app__sidebar-open': showSidebar }">
        <AppHeader @open-signin-modal="openSigninModal" />
        <div
          :class="{ 'app-overlay__open': showSidebar }"
          class="app-overlay"
          @click.prevent="closeSidebar"
        />
        <div :class="{ 'app-container__sidebar': pageSidebar }" class="container app-container">
          <router-view
            @open-forgot-password="openForgotPasswordModal"
            @open-signin="openSigninModal"
          />
        </div>

        <BaseModal
          v-if="showSigninModal"
          @close="closeSigninModal"
          @open-forgot-password="openForgotPasswordModal"
        >
          <UserLogin slot="body" />
        </BaseModal>

        <BaseModal
          v-if="showForgotModal"
          @close="closeForgotPasswordModal"
          @open-signin="openSigninModal"
        >
          <UserForgot slot="body" />
        </BaseModal>
      </div>
    </template>
  </div>
</template>

<script>
import AppHeader from '@/components/layout/AppHeader'
import UserForgot from '@/components/Auth/UserForgot'
import UserLogin from '@/components/Auth/UserLogin'
import BaseModal from '@/components/base/BaseModal'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    AppHeader,
    UserForgot,
    UserLogin,
    BaseModal
  },
  mounted() {
    if (!this.isIframed) {
      // load stripe and FB is not iframed
      var scriptTag = document.createElement('script')
      scriptTag.src = 'https://js.stripe.com/v3/'
      scriptTag.id = 'stripe-library'
      document.getElementsByTagName('head')[0].appendChild(scriptTag)

      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
    }
  },

  data() {
    return {
      showSigninModal: false,
      showForgotModal: false
    }
  },

  computed: {
    isIframed() {
      return this.$route.name === 'iframe-embed'
    },
    ...mapState(['showSidebar']),

    pageSidebar() {
      return this.$route.meta.sidebar
    }
  },

  methods: {
    closeSidebar() {
      this.setSidebar(false)
    },

    openSigninModal() {
      this.showSigninModal = true
      this.showForgotModal = false
    },

    closeSigninModal() {
      this.showSigninModal = false
    },

    openForgotPasswordModal() {
      this.showSigninModal = false
      this.showForgotModal = true
    },

    closeForgotPasswordModal() {
      this.showForgotModal = false
    },

    ...mapActions(['setSidebar'])
  }
}
</script>
