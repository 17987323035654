<template>
  <form class="px-10">
    <div>
      <div>
        <div class="mt-6 sm:mt-5">
          <div
            class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5"
          >
            <label
              for="old_pin"
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Existing Pin
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="flex shadow">
                <input
                  id="old_pin"
                  v-model="form.old_pin"
                  class="flex-1 form-input block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
          </div>

          <div
            class="mb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray sm:pt-5"
          >
            <label
              for="new_pin"
              class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              New Pin
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div class="flex shadow">
                <input
                  id="new_pin"
                  v-model="form.pin"
                  class="flex-1 form-input block w-full min-w-0 rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
          </div>

          <div class="flex justify-end">
            <span class="inline-flex rounded-md shadow-sm">
              <button
                v-loading="isLoading"
                class="button button-sm button-green"
                @click.prevent="handleSubmit"
              >
                Save
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import UserService from "@/api/UserService";
import _get from "lodash.get";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "UpdatePinForm",

  data() {
    return {
      form: {
        old_pin: null,
        pin: null
      },
      isLoading: false
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      token: state => state.auth.token
    })
  },

  mounted() {
    this.form.old_pin = this.user.pin;
  },

  methods: {
    ...mapActions("auth", ["setUser"]),
    async handleSubmit() {
      this.isLoading = true;
      try {
        const updatedResponse = await UserService.updatePin({
          old_pin: this.form.old_pin,
          pin: this.form.pin
        });

        this.$toasted.success(
          "We've successfully saved your PIN for future purchases.",
          {
            duration: 4000
          }
        );
        this.setUser({
          token: this.token,
          user: { ...this.user, pin: this.form.pin }
        });
        this.form.old_pin = this.form.pin;
        this.form.pin = null;
      } catch (ex) {
        this.$toasted.error(
          _get(ex, "data.error", "There was an error while updating."),
          {
            duration: 8000
          }
        );
      }
      this.isLoading = false;
    },

    setForm(user) {
      this.form.email = _get(user, "email");
      this.form.first_name = _get(user, "first_name");
      this.form.last_name = _get(user, "last_name");
      this.form.phone = _get(user, "phone");
      this.form.title = _get(user, "title");
    }
  }
};
</script>
