export function initialState() {
  return {
    showSidebar: false,
    referrer: '',
    currentCountryCode: '',
    currentCurrency: '',
  }
}

export default initialState()
