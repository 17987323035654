<template>
  <select
    class="donate-card__input_full"
    required
    @change="$emit('input', $event.target.value)"
  >
    <option value disabled selected>Select Card *</option>
    <option class="select-dash" disabled="disabled">----</option>
    <option
      v-for="card in cards"
      :key="card.id"
      :value="card.id"
      :selected="card.id === value"
      >{{ `${card.brand} card ends with ${card.last4}` }}</option
    >
  </select>
</template>
<script>
export default {
  name: "CardPicker",

  props: {
    cards: {
      type: [Array],
      required: true
    },
    value: {
      type: String,
      required: true
    }
  }
};
</script>
