export default {
  methods: {
    inputState(validator) {
      if (!validator.$dirty) {
        return null;
      }
      if (validator.$error) {
        return "invalid";
      }
      return "valid";
    }
  }
};
